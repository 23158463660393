import React, { useState, useEffect, useRef } from 'react';
import { FaCheckCircle, FaTimesCircle, FaCoins } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './PreviousBets.css';
import { fetchData } from '../api'; // Ensure fetchData handles token correctly
import { useKeycloak } from '../KeycloakProvider';
import { getPlayerId } from '../components/PlayerLookup';
import { teamNameToAbbreviation } from '../components/TeamDictionary';


// Helper function to map ticket.prop to the proper feature code.
const getFeatureFromProp = (prop) => {
  if (!prop) return ''; // fallback if prop is not defined
  const upperProp = prop.toUpperCase();
  const hasPTS = upperProp.includes('PTS');
  const hasREB = upperProp.includes('REB');
  const hasAST = upperProp.includes('AST');
  if (hasPTS && hasREB && hasAST) return 'pts_ast_reb';
  if (hasPTS && hasREB) return 'pts_reb';
  if (hasPTS && hasAST) return 'pts_ast';
  if (hasREB && hasAST) return 'ast_reb';
  if (hasPTS) return 'pts';
  if (hasREB) return 'reb';
  if (hasAST) return 'ast';
  return prop.toLowerCase();
};

const getResultIcon = (player) => {
  if (player.result === "Pending" || player.result === "DNP") {
    return <span className="result-text">{player.result}</span>;
  }
  return player.isWin ? (
    <FaCheckCircle className="icon green" />
  ) : (
    <FaTimesCircle className="icon red" />
  );
};

const getResultForBet = (bet) => {
  if (bet.result === "Pending" || bet.result === "void" || bet.result === "DNP") {
    return <span className="result-text">{bet.result}</span>;
  }
  if (bet.type === 'Singles') {
    if (bet.resolvedCount && bet.resolvedCount > 1) {
      const iconColor = bet.winCount > bet.resolvedCount / 2 ? 'green' : 'red';
      return <FaCoins className={`icon coin ${iconColor}`} />;
    } else {
      return bet.result === 'win' ? (
        <FaCheckCircle className="icon green" />
      ) : (
        <FaTimesCircle className="icon red" />
      );
    }
  } else if (bet.type === 'Parlay') {
    return bet.result === 'win' ? (
      <FaCheckCircle className="icon green" />
    ) : (
      <FaTimesCircle className="icon red" />
    );
  }
  return null;
};

const PreviousBets = () => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [betsData, setBetsData] = useState([]);
  const [playerDetails, setPlayerDetails] = useState({});
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [endDate, setEndDate] = useState(new Date());
  // Remove the invested filter; defaultInvested is set to 10 if no amount is provided.
  const defaultInvested = 10;
  const [results, setResults] = useState({});
  const { token, keycloakId } = useKeycloak();

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  // Fetch tickets using start_date, end_date, and auth0_id.
  const fetchTickets = async () => {
    try {
      const start = startDate.toISOString().split('T')[0];
      const end = endDate.toISOString().split('T')[0];
      const queryParams = new URLSearchParams({
        start_date: start,
        end_date: end,
        auth0_id: keycloakId,
      });
      const url = `user-picks/filter_tickets_view/?${queryParams.toString()}`;
      const response = await fetchData(url, token, 'GET');
      if (response && response.tickets) {
        setTickets(response.tickets);
        fetchResultsForTickets(response.tickets);
      } else {
        console.error('No tickets found in the response.');
        setTickets([]);
        setBetsData([]);
        setPlayerDetails({});
      }
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  // For each ticket, fetch prediction results.
  const fetchResultsForTickets = async (tickets) => {
    const resultsData = {};
    await Promise.all(
      tickets.map(async (ticket) => {
        const queryParams = new URLSearchParams({
          player_name: ticket.player_bet_on,
          next_opponent: ticket.opponent,
          game_date: ticket.game_date,
          feature: ticket.prop ? getFeatureFromProp(ticket.prop) : ticket.feature,
          over_under: ticket.over_under,
        });
        const url = `player-prediction-results/filter_predictions/?${queryParams.toString()}`;
        try {
          const resultData = await fetchData(url, token, 'GET');
          if (resultData && resultData.length > 0) {
            resultsData[ticket.id] = resultData[0].hit ? "hit" : "miss";
          } else {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            const gameDateObj = new Date(ticket.game_date);
            gameDateObj.setHours(0, 0, 0, 0);
            resultsData[ticket.id] = currentDate > gameDateObj ? "DNP" : "Pending";
          }
        } catch (error) {
          console.error(`Error fetching result for ticket ID ${ticket.id}:`, error);
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          const gameDateObj = new Date(ticket.game_date);
          gameDateObj.setHours(0, 0, 0, 0);
          resultsData[ticket.id] = currentDate > gameDateObj ? "DNP" : "Pending";
        }
      })
    );
    setResults(resultsData);
  };

  // Process tickets: group them by ticket_id and calculate amounts, potential returns, and winnings.
  const processTickets = (tickets) => {
    const groupedTickets = tickets.reduce((acc, ticket) => {
      if (!acc[ticket.ticket_id]) {
        acc[ticket.ticket_id] = [];
      }
      acc[ticket.ticket_id].push(ticket);
      return acc;
    }, {});

    const betsDataArray = [];
    const playerDetailsObj = {};

    Object.keys(groupedTickets).forEach((ticketId) => {
      const ticketGroup = groupedTickets[ticketId];
      const firstTicket = ticketGroup[0];
      // Use bet_type from the database.
      const betType = firstTicket.bet_type; // "Singles" or "Parlay"
      // For each ticket, read the saved "amount" field; if missing, default to 10.
      // For parlay, all legs share the same overall amount.
      const amountValue = firstTicket.amount ? parseFloat(firstTicket.amount) : defaultInvested;
      const amountStr = `${amountValue}$`;

      // Calculate potential return.
      let potentialReturnValue = 0;
      if (betType.toLowerCase() === "parlay") {
        let productOdds = 1;
        ticketGroup.forEach(ticket => {
          if (results[ticket.id] !== "DNP") {
            productOdds *= parseFloat(ticket.odds);
          }
        });
        potentialReturnValue = amountValue * productOdds;
      } else {
        ticketGroup.forEach(ticket => {
          potentialReturnValue += amountValue * parseFloat(ticket.odds);
        });
      }
      const potentialReturn = `${potentialReturnValue.toFixed(2)}$`;

      // Determine overall result.
      const pendingCount = ticketGroup.filter(ticket => results[ticket.id] === "Pending").length;
      const dnpCount = ticketGroup.filter(ticket => results[ticket.id] === "DNP").length;
      const resolvedTickets = ticketGroup.filter(ticket => {
        const res = results[ticket.id];
        return res === "hit" || res === "miss";
      });
      const winCount = resolvedTickets.filter(ticket => results[ticket.id] === "hit").length;
      let overall = "";
      if (pendingCount > 0) {
        overall = "Pending";
      } else if (betType.toLowerCase() === "singles" && ticketGroup.length === 1 && dnpCount > 0) {
        overall = "void";
      } else if (resolvedTickets.length === 0) {
        overall = "void";
      } else {
        if (betType.toLowerCase() === "parlay") {
          overall = winCount === resolvedTickets.length ? "win" : "loss";
        } else {
          overall = winCount > resolvedTickets.length / 2 ? "win" : "loss";
        }
      }
      const winnings = overall === "win" ? `${potentialReturnValue.toFixed(2)}$` : "0$";

      betsDataArray.push({
        id: ticketId,
        date: firstTicket.game_date,
        type: betType,
        players: ticketGroup.length,
        amount: amountStr,
        potentialReturn: potentialReturn,
        winnings: winnings,
        result: overall,
        resolvedCount: resolvedTickets.length,
        winCount: winCount,
      });

      // Build player details for each leg, including the individual amount.
      playerDetailsObj[ticketId] = ticketGroup.map(ticket => ({
        player: ticket.player_bet_on,
        team: ticket.team,
        vs: ticket.opponent,
        prop: ticket.prop || ticket.metric || ticket.featureDisplay || 'N/A',
        odds: ticket.odds,
        line: ticket.threshold,
        pick: ticket.over_under,
        modelPick: parseFloat(ticket.prediction).toFixed(0),
        result: results[ticket.id],
        isWin: results[ticket.id] === "hit",
        modelProbability: (parseFloat(ticket.probability) * 100).toFixed(2),
        amount: ticket.amount ? parseFloat(ticket.amount).toFixed(2) : defaultInvested.toFixed(2),
      }));
    });

    setBetsData(betsDataArray);
    setPlayerDetails(playerDetailsObj);
  };

  useEffect(() => {
    fetchTickets();
  }, [startDate, endDate]);

  useEffect(() => {
    if (tickets.length > 0 && Object.keys(results).length > 0) {
      processTickets(tickets);
    } else {
      setBetsData([]);
      setPlayerDetails({});
    }
  }, [tickets, results]);

  return (
    <div className="previous-bets-container">
      <h2 className="table-title">Previous Picks</h2>
      
      <div className="filter-container">
        <div className="filter-inputs">
          <label>Start Date:</label>
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
          <label>End Date:</label>
          <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
        </div>
        <button className="apply-filter-btn" onClick={fetchTickets}>Apply Filter</button>
      </div>

      {betsData.length > 0 ? (
        <table className="bets-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Players</th>
              <th>Amount</th>
              <th>Potential Return</th>
              <th>Winnings</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {betsData.map((bet, index) => (
              <React.Fragment key={bet.id}>
                <tr className="bet-row" onClick={() => setExpandedRow(expandedRow === index ? null : index)}>
                  <td>{bet.date}</td>
                  <td>{bet.type}</td>
                  <td>{bet.players}</td>
                  <td>{bet.amount}</td>
                  <td>{bet.potentialReturn}</td>
                  <td>{bet.winnings}</td>
                  <td className="ticket-result">{getResultForBet(bet)}</td>
                </tr>
                {expandedRow === index && (
                  <tr className="expanded-row">
                    <td colSpan="7">
                      <table className="player-details-table">
                        <thead>
                          <tr>
                            <th>Player</th>
                            <th>Team</th>
                            <th>VS</th>
                            <th>Prop</th>
                            <th>Odds</th>
                            <th>Line</th>
                            <th>Pick</th>
                            <th>Model Pick</th>
                            <th>Amount</th>
                            <th>Result</th>
                            <th>Model Probability</th>
                          </tr>
                        </thead>
                        <tbody>
                          {playerDetails[bet.id]?.map((player, pIndex) => (
                            <tr key={pIndex}>
                              <td className="player-name">{player.player}</td>
                              <td>{player.team}</td>
                              <td>{player.vs}</td>
                              <td>{player.prop}</td>
                              <td>{player.odds}</td>
                              <td>{player.line}</td>
                              <td>{player.pick}</td>
                              <td>{player.modelPick}</td>
                              <td>{player.amount}$</td>
                              <td className="result">
                                {(player.result === "Pending" || player.result === "DNP") ? (
                                  <span className="result-text">{player.result}</span>
                                ) : (
                                  <>
                                    {player.result}
                                    <span>{getResultIcon(player)}</span>
                                  </>
                                )}
                              </td>
                              <td>{player.modelProbability}%</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No bets found for the selected date range.</p>
      )}
    </div>
  );
};

export default PreviousBets;
