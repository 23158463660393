import React, { useEffect, useState } from 'react';
import { usePlayerColors } from './PlayerColorContext';
import { fetchData } from '../api';
import { teamsDict } from '../components/TeamInfo';
import { teamNameToAbbreviation } from '../components/TeamDictionary';
import './TeamVersus.css';
import { useKeycloak } from '../KeycloakProvider';
import nbaSchedule from '../matchup_dashboard_components/data/nba_schedule.json';

// Helper function to add ordinal suffixes (e.g., 1st, 2nd, 3rd)
function ordinalSuffixOf(i) {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) return 'st';
  if (j === 2 && k !== 12) return 'nd';
  if (j === 3 && k !== 13) return 'rd';
  return 'th';
}

// Formats a date string (YYYY-MM-DD) to a format like "January 1st"
function formatDateWithoutYear(dateStr) {
  const date = new Date(dateStr);
  const options = { month: 'long', day: 'numeric' };
  return date
    .toLocaleDateString('en-US', options)
    .replace(/\b(\d{1,2})\b/, (n) => `${n}${ordinalSuffixOf(n)}`);
}

const TeamVersus = () => {
  const {
    teamColors,
    opponentTeamColors,
    teamLogoSrc,
    opponentLogoSrc,
    venue,
    location,
    selectedTeam,
    opponentName,
    nextGameDetails,
    setNextGameDetails,
  } = usePlayerColors();
  const { token } = useKeycloak();

  // Use first color or default
  const teamColor = teamColors[0] || '#003366';
  const bestOpponentColor = '#ffffff';

  const [teamRecord, setTeamRecord] = useState(null);
  const [opponentRecord, setOpponentRecord] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchTeamRecords = async () => {
      if (teamsDict[selectedTeam] && teamsDict[opponentName]) {
        const homeTeamId = teamsDict[selectedTeam].team_id;
        const awayTeamId = teamsDict[opponentName].team_id;

        try {
          const [teamData, opponentData] = await Promise.all([
            fetchData(`team_overall_stats/?team_id=${homeTeamId}&metrics=w&metrics=l`, token),
            fetchData(`team_overall_stats/?team_id=${awayTeamId}&metrics=w&metrics=l`, token),
          ]);

          if (isMounted) {
            setTeamRecord(`${teamData.w}-${teamData.l}`);
            setOpponentRecord(`${opponentData.w}-${opponentData.l}`);
          }
        } catch (error) {
          console.error('Error fetching team records:', error);
        }
      }
    };

    const fetchNextGame = () => {
      const currentDate = new Date();
      const minDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
      const threeDaysLater = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000);

      const nextMatch = nbaSchedule.find((match) => {
        const matchDate = new Date(match.Date);
        return (
          ((match['Home Team'] === selectedTeam && match['Away Team'] === opponentName) ||
            (match['Home Team'] === opponentName && match['Away Team'] === selectedTeam)) &&
          matchDate >= minDate &&
          matchDate <= threeDaysLater
        );
      });

      if (nextMatch && isMounted) {
        setNextGameDetails({
          date: formatDateWithoutYear(nextMatch.Date),
          time: nextMatch['Tip-off Time'],
        });
      }
    };

    fetchTeamRecords();
    fetchNextGame();

    return () => {
      isMounted = false;
    };
  }, [selectedTeam, opponentName, token, setNextGameDetails]);

  const backgroundStyle = {
    background: `linear-gradient(135deg, ${teamColor} 46.5%, ${bestOpponentColor} 46.5%)`,
  };

  return (
    <div className="match-card" style={backgroundStyle}>
      <div className="top-section">
        <div
          className="date-time"
          style={{ color: 'white', fontWeight: 550, fontSize: '18px', fontFamily: 'Montserrat' }}
        >
          {nextGameDetails?.date || 'Loading...'}
        </div>
        <div
          className="time"
          style={{ color: 'white', fontWeight: 550, fontSize: '17px', fontFamily: 'Montserrat' }}
        >
          {nextGameDetails?.time || ''}
        </div>
      </div>
      <div className="middle-section">
        <div className="team">
          <img src={teamLogoSrc || '/default-logo.png'} alt="Team Logo" className="logo" />
          <div className="team1-score" style={{ color: bestOpponentColor, fontSize: '18px' }}>
            {teamRecord || 'Loading...'}
          </div>
        </div>
        <div className="team">
          <img src={opponentLogoSrc || '/default-logo.png'} alt="Opponent Logo" className="logo2" />
          <div className="team2-score" style={{ color: teamColor, fontSize: '18px' }}>
            {opponentRecord || 'Loading...'}
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div
          className="location"
          style={{ color: teamColor, fontStyle: 'italic', fontSize: '18px', fontFamily: 'Montserrat' }}
        >
          {location}
        </div>
        <div className="venue" style={{ color: teamColor }}>
          {venue}
        </div>
      </div>
    </div>
  );
};

export default TeamVersus;
