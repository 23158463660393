import React from 'react';
import './Matchup.css';

// Helper to format a date string from "YYYY-MM-DD" to "DD/MM"
const formatDate = (dateStr) => {
  if (!dateStr) return '';
  const parts = dateStr.split('-');
  if (parts.length < 3) return dateStr;
  return `${parts[2]}/${parts[1]}`;
};

// Helper to format the tip-off time.
// It removes any trailing "ET" from the original string,
// strips out the ":00" if present, and then appends a single " ET".
const formatTipOffTime = (timeStr) => {
  if (!timeStr) return '';
  let trimmed = timeStr.trim();
  // Remove trailing "ET" (case insensitive)
  trimmed = trimmed.replace(/\s*ET$/i, '');
  // Remove ":00" if it exists (but leave minutes if they’re not "00")
  trimmed = trimmed.replace(/:00\b/, '');
  return '   ' + trimmed + ' ET';
};

// Combines the formatted date and tip-off time.
const formatMatchupDateTime = (dateStr, timeStr) => {
  const formattedDate = formatDate(dateStr);
  const formattedTime = formatTipOffTime(timeStr);
  return `${formattedDate} ${formattedTime}`;
};

const Matchup = ({
  date,       // Expected format: "YYYY-MM-DD"
  team1Logo,
  team2Logo,
  team1Name,
  team2Name,
  time,       // Expected format: e.g., "10:00 pm ET" or "7:30 pm ET"
  topPicks,
  isHomeGame, // Determines if it's a home game (@) or neutral game (vs)
}) => {
  return (
    <div className="matchup-container">
      {/* Matchup Details */}
      <div className="matchup-details">
        {/* Away Team */}
        <div className="team-container">
          <img src={team2Logo} alt={`${team2Name} logo`} className="team-logo" />
          <span className="team-name">{team2Name}</span>
        </div>

        {/* Versus or Home/Away Indicator */}
        <span className="vs">{isHomeGame ? '@' : 'vs'}</span>

        {/* Home Team */}
        <div className="team-container">
          <img src={team1Logo} alt={`${team1Name} logo`} className="team-logo" />
          <span className="team-name">{team1Name}</span>
        </div>

        {/* Picks Statistics */}
        <div className="pick-stats">
          <span className="top-picks">{topPicks} Top Picks</span>
        </div>
      </div>

      {/* Display Matchup Date & Formatted Time */}
      <div className="matchup-bottom">
        <div className="matchup-datetime">
          {formatMatchupDateTime(date, time)}
        </div>
        {isHomeGame && <div className="home-team">@{team1Name}</div>}
      </div>
    </div>
  );
};

export default Matchup;
