import React, { useState, useEffect } from 'react';
import './SelectedPlayers.css';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { fetchData } from '../api';
import { useKeycloak } from '../KeycloakProvider';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import { players } from '../components/PlayerInfo';
import { teamNameToAbbreviation } from '../components/TeamDictionary';

// Mapping for display purposes
const featureMapShow = {
  pts: 'Points',
  reb: 'Rebounds',
  ast: 'Assists',
  pts_ast: 'Pts+Ast',
  pts_reb: 'Pts+Reb',
  reb_ast: 'Reb+Ast',
  ast_reb: 'Reb+Ast',
  pts_ast_reb: 'Pts+Ast+Reb'
};

// Mapping from cached feature (uppercase) to market key (as defined in your DB)
const marketMapping = {
  'PTS': 'player_points',
  'AST': 'player_assists',
  'REB': 'player_rebounds',
  'PTS_AST': 'player_points_assists',
  'PTS_REB': 'player_points_rebounds',
  'AST_REB': 'player_rebounds_assists',
  'PTS_AST_REB': 'player_points_rebounds_assists'
};

// Allowed features (lowercase) that should be shown.
const allowedFeatures = ['pts', 'ast', 'reb', 'pts_ast', 'pts_reb', 'ast_reb', 'pts_ast_reb'];

const SelectedPlayers = ({
  playerIds = [],
  selectedMatchup = null,
  addToParlayBuilder,
  removeFromSelectedPlayers,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [playerToRemove, setPlayerToRemove] = useState(null);
  const [playerData, setPlayerData] = useState([]);
  const [editingField, setEditingField] = useState({});
  const { token } = useKeycloak();
  const { cart, setCart } = usePlayerColors();
  const [featureFilter, setFeatureFilter] = useState('All');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  // Fetch players either from localStorage cache or API.
  useEffect(() => {
    const fetchPlayerData = async () => {
      if (!token) {
        return;
      }
      try {
        let data = [];
        if (!selectedMatchup) {
          // Read players from localStorage (the cache)
          const cachedPlayers = JSON.parse(localStorage.getItem('savedPlayers')) || [];
          const mappedData = await Promise.all(
            cachedPlayers.map(async (cp) => {
              const playerInfo = players[cp.player_id] || {};
              const featureKey = (cp.feature || '').toLowerCase();
              const marketKey = marketMapping[featureKey.toUpperCase()] || '';
              const pickType = (cp.over_under && cp.over_under.toLowerCase() === 'over')
                ? 'over'
                : 'under';

              if (!cp.sportsbook && cp.player_name && marketKey) {
                try {
                  const propsData = await fetchData(
                    `player_props/get_props/?player_name=${encodeURIComponent(cp.player_name)}&market=${encodeURIComponent(marketKey)}`,
                    token
                  );
                  cp.propsArray = propsData;
                  if (propsData && propsData.length > 0) {
                    const validEntries = propsData.filter((entry) => {
                      return pickType === 'over'
                        ? entry.over_odds !== undefined
                        : entry.under_odds !== undefined;
                    });
                    if (validEntries.length > 0) {
                      const bestEntry = validEntries.reduce((max, entry) => {
                        const odds = pickType === 'over' ? entry.over_odds : entry.under_odds;
                        const currentMax = pickType === 'over'
                          ? (max.over_odds || -Infinity)
                          : (max.under_odds || -Infinity);
                        return odds > currentMax ? entry : max;
                      }, {});
                      if (Object.keys(bestEntry).length > 0) {
                        cp.sportsbook = bestEntry.sportsbook;
                        cp.odds = pickType === 'over'
                          ? bestEntry.over_odds
                          : bestEntry.under_odds;
                        cp.threshold = pickType === 'over'
                          ? bestEntry.over_threshold
                          : bestEntry.under_threshold;
                      } else {
                        cp.sportsbook = "Default";
                        cp.odds = 1.86;
                      }
                    } else {
                      cp.sportsbook = "Default";
                      cp.odds = 1.86;
                    }
                  } else {
                    cp.sportsbook = "Default";
                    cp.odds = 1.86;
                  }
                } catch (apiError) {
                  cp.sportsbook = "Default";
                  cp.odds = 1.86;
                }
              }
              return {
                ...cp,
                // Map the feature for display.
                featureDisplay: featureMapShow[(cp.feature || '').toLowerCase()] || cp.feature,
                ...playerInfo,
              };
            })
          );
          // Filter out any players whose feature is not in allowedFeatures.
          data = mappedData.filter(item => {
            const feat = (item.feature || item.featureDisplay || '').toLowerCase();
            return allowedFeatures.includes(feat);
          });
        } else {
          // For a matchup, fetch predictions for both teams.
          const { homeTeam, awayTeam } = selectedMatchup;
          const homeTeamAbbreviation = teamNameToAbbreviation[homeTeam] || 'na';
          const awayTeamAbbreviation = teamNameToAbbreviation[awayTeam] || 'na';
          if (!homeTeamAbbreviation || !awayTeamAbbreviation) {
            return;
          }
          const queryParamsHome = new URLSearchParams({
            latest: 'true',
            team_abbreviation: homeTeamAbbreviation,
          });
          const queryParamsAway = new URLSearchParams({
            latest: 'true',
            team_abbreviation: awayTeamAbbreviation,
          });
          const [homeTeamData, awayTeamData] = await Promise.all([
            fetchData(`player-feature-predictions/filter_predictions/?${queryParamsHome.toString()}`, token).catch(() => []),
            fetchData(`player-feature-predictions/filter_predictions/?${queryParamsAway.toString()}`, token).catch(() => []),
          ]);
          const allPlayerData = [...homeTeamData, ...awayTeamData];
          // Add featureDisplay mapping for matchup data.
          data = allPlayerData.map((p) => ({
            ...p,
            featureDisplay: featureMapShow[(p.feature || '').toLowerCase()] || p.feature,
          }))
          .filter(item => {
            const feat = (item.feature || item.featureDisplay || '').toLowerCase();
            return allowedFeatures.includes(feat);
          });
        }
        setPlayerData(data);
      } catch (error) {
        setPlayerData([]);
      }
    };
    fetchPlayerData();
  }, [selectedMatchup, token]);

  const confirmRemovePlayer = () => {
    const updatedData = playerData.filter(
      (item) => String(item.player_id) !== String(playerToRemove)
    );
    setPlayerData(updatedData);
    const updatedCart = cart.filter(
      (item) => String(item.player_id) !== String(playerToRemove)
    );
    setCart(updatedCart);
    localStorage.setItem('savedPlayers', JSON.stringify(updatedCart));
    if (removeFromSelectedPlayers) {
      removeFromSelectedPlayers(playerToRemove);
    }
    setShowModal(false);
  };

  const handleDeleteClick = (player_id) => {
    setPlayerToRemove(player_id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setPlayerToRemove(null);
  };

  const handleInputChange = (index, field, value) => {
    setPlayerData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [field]: value,
      };
      return newData;
    });
  };

  const handleFieldClick = (index, field) => {
    setEditingField({ index, field });
  };

  const handleBlur = () => {
    setEditingField({});
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const filteredPlayerData = playerData.filter((playerItem) => {
    if (featureFilter === 'All') {
      return true;
    }
    const displayName = featureMapShow[(playerItem.feature || '').toLowerCase()] || playerItem.feature;
    return displayName === featureFilter;
  });

  const displayPlayerName = (playerName) => {
    if (playerName.length > 16) {
      const [firstName, ...lastNameParts] = playerName.split(' ');
      const lastName = lastNameParts.join(' ');
      return `${firstName[0]}. ${lastName}`;
    }
    return playerName;
  };

  const sortedPlayerData = React.useMemo(() => {
    if (sortConfig.key !== null) {
      return [...filteredPlayerData].sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === 'player_name') {
          aValue = a.player_name.toLowerCase();
          bValue = b.player_name.toLowerCase();
        } else if (
          sortConfig.key === 'feature_prediction' ||
          sortConfig.key === 'feature_probability'
        ) {
          aValue = Number(aValue);
          bValue = Number(bValue);
        } else if (sortConfig.key === 'game_date') {
          aValue = new Date(`${a.game_date} ${a.game_time}`);
          bValue = new Date(`${b.game_date} ${b.game_time}`);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    } else {
      return filteredPlayerData;
    }
  }, [filteredPlayerData, sortConfig]);

  return (
    <div className="selected-players-container">
      <div className="selected-players-header">
        <h3 className="selected-players-title">Selected Players</h3>
        <div className="selected-players-filter">
          <label htmlFor="featureFilter">Prop: </label>
          <select
            id="featureFilter"
            value={featureFilter}
            onChange={(e) => setFeatureFilter(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Points">Points</option>
            <option value="Rebounds">Rebounds</option>
            <option value="Assists">Assists</option>
            <option value="Pts+Ast">Pts+Ast</option>
            <option value="Pts+Reb">Pts+Reb</option>
            <option value="Reb+Ast">Reb+Ast</option>
            <option value="Pts+Ast+Reb">Pts+Ast+Reb</option>
          </select>
        </div>
      </div>

      {sortedPlayerData.length > 0 ? (
        <table className="selected-players-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('player_name')}>Players</th>
              <th onClick={() => handleSort('next_opponent')}>Opp</th>
              <th onClick={() => handleSort('feature_prediction')}>Proj</th>
              <th onClick={() => handleSort('feature_probability')}>Conf</th>
              <th>Odds</th>
              <th onClick={() => handleSort('sportsbook')}>Bookie</th>
              <th onClick={() => handleSort('over_under')}>Pick</th>
              <th>Add/Remove</th>
            </tr>
          </thead>
          <tbody>
            {sortedPlayerData.map((playerItem, index) => {
              const playerId =
                playerItem.player_id ||
                Object.keys(players).find(
                  (key) => players[key].name === playerItem.player_name
                );
              const player = players[playerId];
              if (!player) return null;

              const pickType =
                playerItem.over_under &&
                playerItem.over_under.toLowerCase() === 'over'
                  ? 'over'
                  : 'under';

              const displayedOdds =
                playerItem.odds !== undefined
                  ? Number(playerItem.odds).toFixed(2)
                  : '-';

              let availableBookiesForPlayer = [];
              if (playerItem.propsArray && playerItem.propsArray.length > 0) {
                const valid = playerItem.propsArray.filter((entry) => {
                  if (!entry.sportsbook) return false;
                  const hasOdds =
                    pickType === 'over'
                      ? entry.over_odds !== undefined
                      : entry.under_odds !== undefined;
                  return hasOdds;
                });
                availableBookiesForPlayer = valid.map((v) => v.sportsbook);
              }
              const bookiesToShow =
                availableBookiesForPlayer.length > 0
                  ? [...new Set(availableBookiesForPlayer)]
                  : ['Default'];

              // Add team information from player.teamName before sending data to ParlayBuilder
              const handleAddToParlay = () => {
                const combinedData = { ...player, ...playerItem, team: player.teamName };
                console.log('Parlay Builder: Adding player with data:', combinedData);
                addToParlayBuilder(combinedData);
              };

              return (
                <tr key={index}>
                  <td className="selected-players-player-info">
                    <div
                      className="selected-players-logo-container"
                      style={{ backgroundColor: player.teamColors[0] }}
                    >
                      <img
                        src={player.imageSrc}
                        alt={player.name}
                        className="selected-players-player-logo"
                      />
                    </div>
                    <div className="selected-players-details">
                      <span className="selected-players-name">
                        {displayPlayerName(player.name)}
                      </span>
                      <br />
                      <span className="selected-players-points">
                        {playerItem.featureDisplay} {playerItem.threshold}
                      </span>
                    </div>
                  </td>
                  <td>
                    {playerItem.next_opponent}
                    <br />
                    {player.home_or_away === 1 ? 'Home' : 'Away'}
                  </td>
                  <td>{Math.round(playerItem.feature_prediction)}</td>
                  <td>{(playerItem.feature_probability * 100).toFixed(1)}%</td>
                  <td>{displayedOdds}</td>
                  <td>
                    {editingField.index === index && editingField.field === 'sportsbook' ? (
                      <select
                        value={playerItem.sportsbook}
                        onChange={(e) => {
                          const newBookie = e.target.value;
                          let newOdds, newThreshold;
                          if (playerItem.propsArray && playerItem.propsArray.length > 0) {
                            const matchingEntry = playerItem.propsArray.find(
                              (entry) => entry.sportsbook === newBookie
                            );
                            if (matchingEntry) {
                              newOdds =
                                pickType === 'over'
                                  ? matchingEntry.over_odds
                                  : matchingEntry.under_odds;
                              newThreshold =
                                pickType === 'over'
                                  ? matchingEntry.over_threshold
                                  : matchingEntry.under_threshold;
                            }
                          }
                          if (newOdds === undefined || newThreshold === undefined) {
                            newOdds = 1.86;
                            newThreshold = playerItem.threshold;
                          }
                          handleInputChange(index, 'sportsbook', newBookie);
                          handleInputChange(index, 'odds', newOdds);
                          handleInputChange(index, 'threshold', newThreshold);
                        }}
                        onBlur={handleBlur}
                        autoFocus
                        className="selected-players-select"
                      >
                        {bookiesToShow.map((s) => (
                          <option key={s} value={s}>
                            {s}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span onClick={() => handleFieldClick(index, 'sportsbook')}>
                        {playerItem.sportsbook}
                      </span>
                    )}
                  </td>
                  <td>
                    {editingField.index === index && editingField.field === 'over_under' ? (
                      <select
                        value={playerItem.over_under}
                        onChange={(e) =>
                          handleInputChange(index, 'over_under', e.target.value)
                        }
                        onBlur={handleBlur}
                        autoFocus
                        className="selected-players-select"
                      >
                        <option value="Over">Over</option>
                        <option value="Under">Under</option>
                      </select>
                    ) : (
                      <span onClick={() => handleFieldClick(index, 'over_under')}>
                        {playerItem.over_under}
                      </span>
                    )}
                  </td>
                  <td className="selected-players-add-remove-buttons">
                    <button
                      className="selected-players-add-btn"
                      onClick={handleAddToParlay}
                    >
                      <FaPlus />
                    </button>
                    {removeFromSelectedPlayers && (
                      <button
                        className="selected-players-remove-btn"
                        onClick={() => handleDeleteClick(playerId)}
                      >
                        <FaTrashAlt />
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>No players available.</p>
      )}

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Are you sure you want to remove this player?</h3>
            <div className="modal-buttons">
              <button className="confirm-btn" onClick={confirmRemovePlayer}>
                Yes
              </button>
              <button className="cancel-btn" onClick={closeModal}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectedPlayers;
