import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { matchups } from './MatchupInfo';  // Static matchups info
import { teamsDict } from './TeamInfo';
import { teamNameToAbbreviation } from './TeamDictionary';
import Matchup from './matchup_menu_components/Matchup';
import './MatchupMenu.css';
import { useKeycloak } from '../KeycloakProvider';
import { fetchData } from '../api';

const MatchupMenu = () => {
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [currentDate, setCurrentDate] = useState('');
  const [enhancedMatchups, setEnhancedMatchups] = useState([]);
  const navigate = useNavigate();
  const { token } = useKeycloak();

  // Helper to format the current date as "DD/MM"
  const formatCurrentDateOnly = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${day}/${month}`;
  };

  useEffect(() => {
    const now = new Date();
    setCurrentDate(formatCurrentDateOnly(now));
  }, []);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const container = document.querySelector('.matchup-menu');
      if (!container) return;
      const containerWidth = container.offsetWidth;
      const itemWidth = 180; // Adjusted width for each matchup
      const newItemsPerPage = Math.floor(containerWidth / itemWidth) - 1;
      setItemsPerPage(newItemsPerPage || 1);
    };

    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);
    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);

  /**
   * Fetch picks/topPicks from your endpoint:
   * /api/player-feature-predictions/get_top_picks_count?homeTeam=HOME&awayTeam=AWAY
   */
  async function fetchPicksForMatchup(homeTeam, awayTeam) {
    let picks = 0;
    let topPicks = 0;
    try {
      const endpoint = `player-feature-predictions/get_top_picks_count/?homeTeam=${encodeURIComponent(
        teamNameToAbbreviation[homeTeam]
      )}&awayTeam=${encodeURIComponent(teamNameToAbbreviation[awayTeam])}`;
      const response = await fetchData(endpoint, token);
      // The endpoint returns { "count": total_count }
      topPicks = response.count || 0;
      // Example logic for picks; adjust as needed:
      picks = 30 + Math.floor(Math.random() * 61); // random between 30–90
    } catch (error) {
      console.error(`Failed to fetch picks for ${homeTeam} vs ${awayTeam}:`, error);
    }
    return { picks, topPicks };
  }

  // Enhance each matchup with picks/topPicks data on mount
  useEffect(() => {
    let isCancelled = false;
    (async () => {
      const updated = [];
      for (const m of matchups) {
        const [awayTeam, homeTeam] = m.matchup.split(' @ ');
        const { picks, topPicks } = await fetchPicksForMatchup(homeTeam, awayTeam);
        updated.push({
          ...m,
          picks,
          topPicks
          // If your matchup data includes a date property, it will be used.
          // Otherwise, we’ll use the currentDate as a fallback when rendering.
        });
      }
      if (!isCancelled) setEnhancedMatchups(updated);
    })();

    return () => {
      isCancelled = true;
    };
  }, [token]);

  const handleNext = () => {
    if (currentStartIndex + itemsPerPage < enhancedMatchups.length) {
      setCurrentStartIndex(currentStartIndex + itemsPerPage);
    }
  };

  const handlePrevious = () => {
    if (currentStartIndex - itemsPerPage >= 0) {
      setCurrentStartIndex(currentStartIndex - itemsPerPage);
    }
  };

  const handleMatchupClick = (matchup) => {
    const [awayTeam, homeTeam] = matchup.matchup.split(' @ ');
    const awayTeamAbbr = teamNameToAbbreviation[awayTeam] || awayTeam;
    const homeTeamAbbr = teamNameToAbbreviation[homeTeam] || homeTeam;

    navigate(`/MatchupDashboard`, {
      state: {
        matchup: {
          awayTeamAbbr,
          homeTeamAbbr,
          time: matchup.time,
          picks: matchup.picks,
          topPicks: matchup.topPicks,
          // Pass the matchup date if available; otherwise use currentDate
          date: matchup.date || currentDate,
        },
      },
    });
  };

  return (
    <div className="matchup-menu-wrapper">
      <div className="matchup-menu-date-box">
        {/* Side date displays only the current date */}
        <span className="matchup-menu-date">{currentDate}</span>
      </div>
      <div className="matchup-menu-container">
        <div className="matchup-menu">
          {enhancedMatchups
            .slice(currentStartIndex, currentStartIndex + itemsPerPage)
            .map((matchup, index) => {
              const [awayTeam, homeTeam] = matchup.matchup.split(' @ ');
              const awayTeamAbbr = teamNameToAbbreviation[awayTeam] || awayTeam;
              const homeTeamAbbr = teamNameToAbbreviation[homeTeam] || homeTeam;

              return (
                <div
                  key={index}
                  className="matchup-menu-link"
                  onClick={() => handleMatchupClick(matchup)}
                >
                  <Matchup
                    // Pass date (if available) or fallback to currentDate
                    date={matchup.date || currentDate}
                    team1Logo={teamsDict[homeTeam]?.logo || '/default-logo.png'}
                    team2Logo={teamsDict[awayTeam]?.logo || '/default-logo.png'}
                    team1Name={homeTeamAbbr}
                    team2Name={awayTeamAbbr}
                    time={matchup.time}
                    picks={matchup.picks}
                    topPicks={matchup.topPicks}
                    isHomeGame={true}
                  />
                </div>
              );
            })}
        </div>
        <div className="arrows-container">
          <button
            className="arrow-button up"
            onClick={handleNext}
            disabled={currentStartIndex + itemsPerPage >= enhancedMatchups.length}
          ></button>
          <button
            className="arrow-button down"
            onClick={handlePrevious}
            disabled={currentStartIndex === 0}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default MatchupMenu;
