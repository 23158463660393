import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import './InjuryReportTable.css';
import { usePlayerColors } from './PlayerColorContext';
import { combinedInfo } from '../components/CombinedInfo';
import { fetchData } from "../api";
import { useKeycloak } from '../KeycloakProvider';
import teamNameToFullName from '../components/TeamNameToFullName';

const subFeatureMap = {
  "Total": ["pts"],
  "AST": ["ast"],
  "REB": ["reb"],
  "BLK": ["blk"],
  "STL": ["stl"],
  "Pts+Ast": ["pts", "ast"],
  "Pts+Reb": ["pts", "reb"],
  "Ast+Reb": ["ast", "reb"],
  "Pts+Ast+Reb": ["pts", "ast", "reb"]
};

const visualFeatureMap = {
  "Total": "Points",
  "AST": "Assists",
  "REB": "Rebounds",
  "BLK": "Blocks",
  "STL": "Steals",
  "Pts+Ast": "Pts+Ast",
  "Pts+Reb": "Pts+Reb",
  "Ast+Reb": "Ast+Reb",
  "Pts+Ast+Reb": "Pts+Ast+Reb"
};

const InjuryReportTable = ({ selectedFeature }) => {
  const { selectedPlayer, teamColors } = usePlayerColors();
  const { token } = useKeycloak();

  const [isEditing, setIsEditing] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [homeTeamData, setHomeTeamData] = useState(null);
  const [opponentTeamData, setOpponentTeamData] = useState(null);
  const [homeImpactData, setHomeImpactData] = useState({});
  const [opponentImpactData, setOpponentImpactData] = useState({});
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeamLogo, setSelectedTeamLogo] = useState('/default-logo.png');
  const [dataFetched, setDataFetched] = useState(false);

  const wrapperRef = useRef(null);

  // Utility: Map team names consistently.
  const getMappedTeamName = useCallback((teamName) => {
    return teamName === "Los Angeles Clippers" ? "LA Clippers" : teamName;
  }, []);

  // Preload selected team & logo when selectedPlayer changes.
  useEffect(() => {
    if (selectedPlayer) {
      const player = combinedInfo[selectedPlayer];
      if (player) {
        setTeamOptions([player.teamName, player.opponentName]);
        setSelectedTeam(player.teamName);
        setSelectedTeamLogo(player.teamLogoSrc);
      }
    }
  }, [selectedPlayer]);

  // Memoize the display name so that it only recalculates when selectedTeam changes.
  const displayTeamName = useMemo(() => {
    return getMappedTeamName(teamNameToFullName[selectedTeam] || selectedTeam);
  }, [selectedTeam, getMappedTeamName]);

  // Fetch Injury Report Data.
  useEffect(() => {
    let isActive = true;
    if (selectedTeam && token) {
      const playerInfo = combinedInfo[selectedPlayer];
      const isOpponentSelected = selectedTeam === playerInfo?.opponentName;

      const fetchInjuryReport = async () => {
        try {
          const endpoint = `teams/injury_report_view/?team_name=${encodeURIComponent(displayTeamName)}`;
          const data = await fetchData(endpoint, token);
          if (!isActive) return;

          if (isOpponentSelected) {
            setOpponentTeamData(data.filter((p) => p.team_name === displayTeamName));
            setHomeTeamData([]);
          } else {
            setHomeTeamData(data.filter((p) => p.team_name === displayTeamName));
            setOpponentTeamData([]);
          }

          // Update team logo if available.
          const teamInfo = Object.values(combinedInfo).find(
            (info) => info.teamName === selectedTeam
          );
          if (teamInfo) {
            setSelectedTeamLogo(teamInfo.teamLogoSrc);
          }
        } catch (error) {
          console.error('Error fetching injury report:', error);
        }
      };

      fetchInjuryReport();
    }
    return () => {
      isActive = false;
    };
  }, [selectedTeam, token, selectedPlayer, displayTeamName]);

  // Define the fetchAndSumImpacts helper using useCallback.
  const fetchAndSumImpacts = useCallback(async (
    mainPlayerId,
    impactPlayerId,
    teamName,
    subFeatures,
    endpointType
  ) => {
    const results = await Promise.all(
      subFeatures.map(async (feat) => {
        try {
          if (endpointType === 'home') {
            const endpoint = `player-impact/get_player_impact/`;
            const params = new URLSearchParams({
              main_player_id: mainPlayerId,
              impact_player: impactPlayerId,
              team_name: teamName,
              feature: feat,
            });
            const data = await fetchData(`${endpoint}?${params.toString()}`, token);
            return {
              impact_value: data?.impact_value,
              min_impact: data?.min_impact,
              games_without: data?.games_without,
              games_without_in_a_row: data?.games_without_in_a_row,
            };
          } else {
            const endpoint = `team-impact/get_team_impact/`;
            const params = new URLSearchParams({
              player_name: impactPlayerId,
              team_name: teamName,
              feature: feat,
            });
            const data = await fetchData(`${endpoint}?${params.toString()}`, token);
            return { impact_allowed_value: data?.impact_allowed_value };
          }
        } catch (err) {
          console.error(`Error fetching impact for ${impactPlayerId} on feature ${feat}:`, err);
          return null;
        }
      })
    );

    let totalImpact = 0;
    let totalMinImpact = 0;
    let gamesWithoutStore = 0;
    let gamesWithoutInARowStore = 0;

    results.forEach((res) => {
      if (!res) return;
      if (endpointType === 'home') {
        if (res.impact_value !== undefined) totalImpact += res.impact_value;
        if (res.min_impact !== undefined) totalMinImpact += res.min_impact;
        if (res.games_without !== undefined) gamesWithoutStore = res.games_without;
        if (res.games_without_in_a_row !== undefined) gamesWithoutInARowStore = res.games_without_in_a_row;
      } else {
        if (res.impact_allowed_value !== undefined) totalImpact += res.impact_allowed_value;
      }
    });

    totalImpact = Math.round(totalImpact * 10) / 10;
    totalMinImpact = Math.round(totalMinImpact * 10) / 10;

    return {
      totalImpact,
      totalMinImpact,
      gamesWithoutStore,
      gamesWithoutInARowStore,
    };
  }, [token]);

  // Fetch Impact Data.
  useEffect(() => {
    let isActive = true;
    const fetchImpactData = async () => {
      setDataFetched(false);
      if (!selectedFeature || !token || !selectedPlayer) return;

      const playerInfo = combinedInfo[selectedPlayer];
      const isOpponentSelected = selectedTeam === playerInfo?.opponentName;

      const homeImpactResults = {};
      const opponentImpactResults = {};
      const subFeatures = subFeatureMap[selectedFeature] || [];

      if (!isOpponentSelected && homeTeamData && homeTeamData.length > 0) {
        // Process HOME team impact data in parallel.
        const promises = homeTeamData.map(async (item) => {
          const result = await fetchAndSumImpacts(
            selectedPlayer,
            item.player_id,
            getMappedTeamName(teamNameToFullName[playerInfo.teamName] || playerInfo.teamName),
            subFeatures,
            'home'
          );
          return { player_id: item.player_id, result };
        });
        const results = await Promise.all(promises);
        results.forEach(({ player_id, result }) => {
          homeImpactResults[player_id] = {
            impactValue: isNaN(result.totalImpact) ? null : result.totalImpact,
            minImpactValue: isNaN(result.totalMinImpact) ? null : result.totalMinImpact,
            gamesWithout: result.gamesWithoutStore || 0,
            gamesWithoutInARow: result.gamesWithoutInARowStore || 0,
          };
        });
        if (isActive) {
          setHomeImpactData(homeImpactResults);
        }
      }

      if (isOpponentSelected && opponentTeamData && opponentTeamData.length > 0) {
        // Process OPPONENT team impact data in parallel.
        const promises = opponentTeamData.map(async (item) => {
          const { totalImpact } = await fetchAndSumImpacts(
            null,
            item.player_id,
            getMappedTeamName(teamNameToFullName[selectedTeam] || selectedTeam),
            subFeatures,
            'away'
          );
          return { player_id: item.player_id, totalImpact };
        });
        const results = await Promise.all(promises);
        results.forEach(({ player_id, totalImpact }) => {
          opponentImpactResults[player_id] = {
            impactAllowedValue: isNaN(totalImpact)
              ? null
              : Math.round(Math.abs(totalImpact) * 10) / 10,
          };
        });
        if (isActive) {
          setOpponentImpactData(opponentImpactResults);
        }
      }

      if (isActive) {
        setDataFetched(true);
      }
    };

    fetchImpactData();

    return () => {
      isActive = false;
    };
  }, [homeTeamData, opponentTeamData, selectedFeature, selectedPlayer, selectedTeam, token, fetchAndSumImpacts]);

  // Sorting helpers wrapped in useCallback.
  const compareStatus = useCallback((a, b) => {
    const statusPriority = { Out: 1, 'Day-To-Day': 2, 'N/A': 3 };
    const aPriority = statusPriority[a.status] || 4;
    const bPriority = statusPriority[b.status] || 4;
    return aPriority - bPriority;
  }, []);

  const comparePlayers = useCallback((a, b) => {
    const aHasImpact =
      a.impactValue !== null && a.impactValue !== 0 && !isNaN(a.impactValue);
    const bHasImpact =
      b.impactValue !== null && b.impactValue !== 0 && !isNaN(b.impactValue);
    if (aHasImpact && !bHasImpact) return -1;
    if (!aHasImpact && bHasImpact) return 1;
    if (aHasImpact && bHasImpact) return b.impactValue - a.impactValue;
    return compareStatus(a, b);
  }, [compareStatus]);

  // Memoize combined table data so sorting happens only when dependencies change.
  const combinedHomeData = useMemo(() => {
    if (!homeTeamData) return [];
    const data = homeTeamData.map((item) => ({
      ...item,
      impactValue: homeImpactData[item.player_id]?.impactValue || null,
      minImpactValue: homeImpactData[item.player_id]?.minImpactValue || null,
      gamesWithout: homeImpactData[item.player_id]?.gamesWithout || 0,
      gamesWithoutInARow: homeImpactData[item.player_id]?.gamesWithoutInARow || 0,
    }));
    return data.sort(comparePlayers);
  }, [homeTeamData, homeImpactData, comparePlayers]);

  const combinedOpponentData = useMemo(() => {
    if (!opponentTeamData) return [];
    const data = opponentTeamData.map((item) => ({
      ...item,
      impactAllowedValue: opponentImpactData[item.player_id]?.impactAllowedValue || null,
    }));
    return data.sort((a, b) => {
      const aHasImpact =
        a.impactAllowedValue !== null &&
        a.impactAllowedValue !== 0 &&
        !isNaN(a.impactAllowedValue);
      const bHasImpact =
        b.impactAllowedValue !== null &&
        b.impactAllowedValue !== 0 &&
        !isNaN(b.impactAllowedValue);
      if (aHasImpact && !bHasImpact) return -1;
      if (!aHasImpact && bHasImpact) return 1;
      if (aHasImpact && bHasImpact) {
        return b.impactAllowedValue - a.impactAllowedValue;
      }
      return compareStatus(a, b);
    });
  }, [opponentTeamData, opponentImpactData, compareStatus]);

  // Render helpers wrapped in useCallback.
  const renderImpactText = useCallback((impactValue) => {
    if (!dataFetched) return 'Loading...';
    if (impactValue === null || impactValue === 0 || isNaN(impactValue)) {
      return 'No impact';
    }
    return impactValue > 0
      ? `+ ${impactValue} ${visualFeatureMap[selectedFeature]}`
      : `- ${Math.abs(impactValue)} ${visualFeatureMap[selectedFeature]}`;
  }, [dataFetched, selectedFeature]);

  const renderMinImpactText = useCallback((minImpactValue) => {
    if (!dataFetched) return 'Loading...';
    if (minImpactValue === null || isNaN(minImpactValue)) {
      return 'No impact';
    }
    return minImpactValue > 0
      ? `+ ${minImpactValue} Min`
      : `- ${Math.abs(minImpactValue)} Min`;
  }, [dataFetched]);

  const handleTeamChange = useCallback((team) => {
    setSelectedTeam(team);
    setIsEditing(false);
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div className="injury-report-container" style={{ backgroundColor: 'white' }}>
      <div className="table-header" ref={wrapperRef} style={{ color: 'black' }}>
        <img src={selectedTeamLogo} alt="Team Logo" className="injury-logo" />
        <div className="title">Injury Report</div>
        <div
          className="selected-team-container"
          onClick={() => setIsEditing((prev) => !prev)}
        >
          <div className="selected-team">{selectedTeam || 'Select team...'}</div>
          <div className="dropdown-arrow-injury-report">▼</div>
        </div>
        {isEditing && (
          <div
            className="autocomplete-injury-table-container"
            style={{ backgroundColor: teamColors[0] || '#000' }}
          >
            {teamOptions.map((team, index) => (
              <div
                key={index}
                className="autocomplete-injury-table-item"
                onClick={() => handleTeamChange(team)}
                style={{ backgroundColor: teamColors[0] || '#000', color: 'white' }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = teamColors[1] || '#fff';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = teamColors[0] || '#000';
                }}
              >
                {team}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* HOME TEAM TABLE */}
      {selectedTeam === combinedInfo[selectedPlayer]?.teamName && (
        <div className="table-container">
          <table className="injury-report-table" style={{ color: 'black' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Impact</th>
                <th>Min Impact</th>
                <th># Games</th>
                <th>Last x</th>
              </tr>
            </thead>
            <tbody>
              {homeTeamData === null ? (
                <tr>
                  <td colSpan="6">Loading...</td>
                </tr>
              ) : homeTeamData.length > 0 ? (
                combinedHomeData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.player_id || 'N/A'}</td>
                    <td>
                      <span
                        className={`status-indicator ${
                          item.status === 'Day-To-Day'
                            ? 'status-day-to-day'
                            : item.status === 'Out'
                            ? 'status-out'
                            : ''
                        }`}
                      ></span>
                      {item.status === "Day-To-Day" ? "DTD" : item.status || 'N/A'}
                    </td>
                    <td>{renderImpactText(item.impactValue)}</td>
                    <td>{renderMinImpactText(item.minImpactValue)}</td>
                    <td>{item.gamesWithout}</td>
                    <td>{item.gamesWithoutInARow}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* OPPONENT TEAM TABLE */}
      {selectedTeam === combinedInfo[selectedPlayer]?.opponentName && (
        <div className="table-container">
          <table className="injury-report-table" style={{ color: 'black' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Impact</th>
              </tr>
            </thead>
            <tbody>
              {opponentTeamData === null ? (
                <tr>
                  <td colSpan="3">Loading...</td>
                </tr>
              ) : opponentTeamData.length > 0 ? (
                combinedOpponentData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.player_id || 'N/A'}</td>
                    <td>
                      <span
                        className={`status-indicator ${
                          item.status === 'Day-To-Day'
                            ? 'status-day-to-day'
                            : item.status === 'Out'
                            ? 'status-out'
                            : ''
                        }`}
                      ></span>
                      {item.status === "Day-To-Day" ? "DTD" : item.status || 'N/A'}
                    </td>
                    <td>{renderImpactText(item.impactAllowedValue)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default InjuryReportTable;
