import React, { useMemo } from 'react';
import { usePlayerColors } from './PlayerColorContext';
import useFetchL15TableData from './data/Last15TableData';
import './L15Table.css';

const getMetricName = (metric) => {
  const metricMappings = {
    pts: 'PTS',
    min: 'MIN',
    fgm: 'FGM',
    fga: 'FGA',
    fg3m: '3PTM',
    fg3a: '3PTA',
    ftm: 'FTM',
    fta: 'FTA',
    fg_percent: 'FG%',
    fg3_percent: '3PT%',
    ft_percent: 'FT%',
    home_away_pts: 'Home/Away PTS',
    ts_percent: 'TS%',
    ast: 'AST',
    home_away_ast: 'Home AST',
    secondaryassists: '2nd AST',
    freethrowassists: 'FT AST',
    passes: 'PASS',
    touches: 'TOUCH',
    reb: 'REB',
    oreb: 'OREB',
    dreb: 'DREB',
    home_away_reb: 'Home REB',
    reboundchancesdefensive: 'DReb Chances',
    reboundchancesoffensive: 'OReb Chances',
    reboundchancestotal: 'Total Reb Chances',
    blk: 'BLK',
    blka: 'BLK Attempts',
    home_away_blk: 'Home BLK',
    stl: 'STL',
    home_away_stl: 'Home STL',
  };

  return metricMappings[metric.replace('_mean_rolling_5_games', '').replace('_mean_rolling_15_games', '')] || metric;
};

const Last15Table = ({ selectedFeature }) => {
  const { selectedPlayer, teamColors } = usePlayerColors();
  const rollingData = useFetchL15TableData(selectedPlayer, selectedFeature);

  const data = useMemo(() => {
    const metrics = Object.keys(rollingData).filter(key => key.includes('_mean_rolling_5_games'));
    
    let processedData = metrics.map(metric => {
      const key5 = metric;
      const key15 = metric.replace('_5_games', '_15_games');
      return {
        metric: getMetricName(metric),
        l5: parseFloat(rollingData[key5] || 0).toFixed(2),
        l15: parseFloat(rollingData[key15] || 0).toFixed(2)
      };
    });

    if (['Pts+Ast', 'Ast+Reb', 'Pts+Reb', 'Pts+Ast+Reb'].includes(selectedFeature)) {
      // Calculate combined stats
      const stats = selectedFeature.split('+');
      const combinedValue5 = stats.reduce((sum, stat) => sum + parseFloat(rollingData[`${stat.toLowerCase()}_mean_rolling_5_games`] || 0), 0);
      const combinedValue15 = stats.reduce((sum, stat) => sum + parseFloat(rollingData[`${stat.toLowerCase()}_mean_rolling_15_games`] || 0), 0);
      
      processedData = [
        {
          metric: selectedFeature,
          l5: combinedValue5.toFixed(2),
          l15: combinedValue15.toFixed(2)
        },
        ...stats.map(stat => ({
          metric: getMetricName(stat.toLowerCase()),
          l5: parseFloat(rollingData[`${stat.toLowerCase()}_mean_rolling_5_games`] || 0).toFixed(2),
          l15: parseFloat(rollingData[`${stat.toLowerCase()}_mean_rolling_15_games`] || 0).toFixed(2)
        })),
        {
          metric: 'MIN',
          l5: parseFloat(rollingData['min_mean_rolling_5_games'] || 0).toFixed(2),
          l15: parseFloat(rollingData['min_mean_rolling_15_games'] || 0).toFixed(2)
        }
      ];

      // Add shooting percentages for features including PTS
      if (selectedFeature.includes('Pts')) {
        ['fg', 'fg3', 'ft'].forEach(shotType => {
          processedData.push({
            metric: `${shotType.toUpperCase()}%`,
            l5: rollingData[`${shotType}_percent_mean_rolling_5_games`],
            l15: rollingData[`${shotType}_percent_mean_rolling_15_games`]
          });
        });
      }

      // Add additional relevant stats to reach 11 features
      const additionalStats = [ 'oreb', 'dreb',  'touches','secondaryAssists', 'passes'];
      let i = 0;
      while (processedData.length < 11 && i < additionalStats.length) {
        const stat = additionalStats[i];
        if (rollingData[`${stat}_mean_rolling_5_games`]) {
          processedData.push({
            metric: getMetricName(stat),
            l5: parseFloat(rollingData[`${stat}_mean_rolling_5_games`] || 0).toFixed(2),
            l15: parseFloat(rollingData[`${stat}_mean_rolling_15_games`] || 0).toFixed(2)
          });
        }
        i++;
      }

      // Limit to 11 features
      processedData = processedData.slice(0, 11);
    }

    return processedData;
  }, [rollingData, selectedFeature]);

  const calculateDiff = (l15, l5) => {
    const diff = ((parseFloat(l5) - parseFloat(l15)) / parseFloat(l15)) * 100;
    return diff.toFixed(0);
  };

  const teamColor = teamColors[0] || '#003366';
  const highlightColor = teamColors[1] || '#FFD700';

  return (
    <div className="l15-stats-container" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="l15-table-header" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="l15-header-title">Stats for {selectedFeature === 'Total' ? 'Points' : selectedFeature}</div>

        <div className="l15-header-icon"></div>
      </div>
      <table className="l15-stats-table">
        <thead>
          <tr>
            <th>Metric</th>
            <th>Last 15</th>
            <th>Last 5</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? 'l15-even-row' : 'l15-odd-row'}
              style={{ backgroundColor: index % 2 === 0 ? 'white' : 'white' }}
            >
              <td>{item.metric}</td>
              <td>{item.l15}</td>
              <td dangerouslySetInnerHTML={{
                __html: `${item.l5} ${
                  parseFloat(item.l5) > parseFloat(item.l15)
                    ? '<span class="l15-arrow-up">↑</span>'
                    : '<span class="l15-arrow-down">↓</span>'
                } <span class="l15-percentage-diff">${calculateDiff(item.l15, item.l5)}%</span>`
              }}></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Last15Table;