import { useState, useEffect, useRef } from 'react';
import { fetchData } from '../../api';  // Import your utility function
import { useKeycloak } from '../../KeycloakProvider';  // Import the Keycloak context

const useFetchL15TableData = (selectedPlayer, selectedFeature) => {
  const [rollingData, setRollingData] = useState({});
  const dataCache = useRef({});

  const { token } = useKeycloak();  // Get the token from Keycloak inside the hook

  const featureMetrics = {
    Total: [
      'pts_mean_rolling_5_games', 'pts_mean_rolling_15_games',
      'min_mean_rolling_5_games', 'min_mean_rolling_15_games',
      'fgm_mean_rolling_5_games', 'fgm_mean_rolling_15_games',
      'fga_mean_rolling_5_games', 'fga_mean_rolling_15_games',
      'fg3m_mean_rolling_5_games', 'fg3m_mean_rolling_15_games',
      'fg3a_mean_rolling_5_games', 'fg3a_mean_rolling_15_games',
      'ftm_mean_rolling_5_games', 'ftm_mean_rolling_15_games',
      'fta_mean_rolling_5_games', 'fta_mean_rolling_15_games',
      'ts_percent_mean_5_games', 'ts_percent_mean_15_games',
      'home_away_pts_mean_rolling_5_games', 'home_away_pts_mean_rolling_15_games',
    ],
    AST: [
      'ast_mean_rolling_5_games', 'ast_mean_rolling_15_games',
      'min_mean_rolling_5_games', 'min_mean_rolling_15_games',
      'home_away_ast_mean_rolling_5_games', 'home_away_ast_mean_rolling_15_games',
      'secondaryassists_mean_rolling_5_games', 'secondaryassists_mean_rolling_15_games',
      'freethrowassists_mean_rolling_5_games', 'freethrowassists_mean_rolling_15_games',
      'passes_mean_rolling_5_games', 'passes_mean_rolling_15_games',
      'touches_mean_rolling_5_games', 'touches_mean_rolling_15_games'
    ],
    REB: [
      'reb_mean_rolling_5_games', 'reb_mean_rolling_15_games',
      'oreb_mean_rolling_5_games', 'oreb_mean_rolling_15_games',
      'dreb_mean_rolling_5_games', 'dreb_mean_rolling_15_games',
      'home_away_reb_mean_rolling_5_games', 'home_away_reb_mean_rolling_15_games',
      'reboundchancesdefensive_mean_rolling_5_games', 'reboundchancesdefensive_mean_rolling_15_games',
      'reboundchancesoffensive_mean_rolling_5_games', 'reboundchancesoffensive_mean_rolling_15_games'
    ],
    BLK: [
      'blk_mean_rolling_5_games', 'blk_mean_rolling_15_games',
      'blka_mean_rolling_5_games', 'blka_mean_rolling_15_games',
      'home_away_blk_mean_rolling_5_games', 'home_away_blk_mean_rolling_15_games',
      'stl_mean_rolling_5_games', 'stl_mean_rolling_15_games',
      'home_away_stl_mean_rolling_5_games', 'home_away_stl_mean_rolling_15_games'
    ],
    STL: [
      'blk_mean_rolling_5_games', 'blk_mean_rolling_15_games',
      'blka_mean_rolling_5_games', 'blka_mean_rolling_15_games',
      'home_away_blk_mean_rolling_5_games', 'home_away_blk_mean_rolling_15_games',
      'stl_mean_rolling_5_games', 'stl_mean_rolling_15_games',
      'home_away_stl_mean_rolling_5_games', 'home_away_stl_mean_rolling_15_games'
    ],
    'Pts+Ast': [
      'pts_mean_rolling_5_games', 'pts_mean_rolling_15_games',
      'ast_mean_rolling_5_games', 'ast_mean_rolling_15_games',
      'min_mean_rolling_5_games', 'min_mean_rolling_15_games',
      'fgm_mean_rolling_5_games', 'fgm_mean_rolling_15_games',
      'fga_mean_rolling_5_games', 'fga_mean_rolling_15_games',
      'fg3m_mean_rolling_5_games', 'fg3m_mean_rolling_15_games',
      'fg3a_mean_rolling_5_games', 'fg3a_mean_rolling_15_games',
      'ftm_mean_rolling_5_games', 'ftm_mean_rolling_15_games',
      'fta_mean_rolling_5_games', 'fta_mean_rolling_15_games',
      'home_away_pts_mean_rolling_5_games', 'home_away_pts_mean_rolling_15_games',
      'home_away_ast_mean_rolling_5_games', 'home_away_ast_mean_rolling_15_games',
      'secondaryassists_mean_rolling_5_games', 'secondaryassists_mean_rolling_15_games',
      'passes_mean_rolling_5_games', 'passes_mean_rolling_15_games',
      'touches_mean_rolling_5_games', 'touches_mean_rolling_15_games',
    ],
    'Ast+Reb': [
      'ast_mean_rolling_5_games', 'ast_mean_rolling_15_games',
      'reb_mean_rolling_5_games', 'reb_mean_rolling_15_games',
      'min_mean_rolling_5_games', 'min_mean_rolling_15_games',
      'oreb_mean_rolling_5_games', 'oreb_mean_rolling_15_games',
      'dreb_mean_rolling_5_games', 'dreb_mean_rolling_15_games',
      'home_away_ast_mean_rolling_5_games', 'home_away_ast_mean_rolling_15_games',
      'home_away_reb_mean_rolling_5_games', 'home_away_reb_mean_rolling_15_games',
      'secondaryassists_mean_rolling_5_games', 'secondaryassists_mean_rolling_15_games',
      'passes_mean_rolling_5_games', 'passes_mean_rolling_15_games',
      'touches_mean_rolling_5_games', 'touches_mean_rolling_15_games',
      'reboundchancesdefensive_mean_rolling_5_games', 'reboundchancesdefensive_mean_rolling_15_games',
      'reboundchancesoffensive_mean_rolling_5_games', 'reboundchancesoffensive_mean_rolling_15_games'
    ],
    'Pts+Reb': [
      'pts_mean_rolling_5_games', 'pts_mean_rolling_15_games',
      'reb_mean_rolling_5_games', 'reb_mean_rolling_15_games',
      'min_mean_rolling_5_games', 'min_mean_rolling_15_games',
      'fgm_mean_rolling_5_games', 'fgm_mean_rolling_15_games',
      'fga_mean_rolling_5_games', 'fga_mean_rolling_15_games',
      'fg3m_mean_rolling_5_games', 'fg3m_mean_rolling_15_games',
      'fg3a_mean_rolling_5_games', 'fg3a_mean_rolling_15_games',
      'ftm_mean_rolling_5_games', 'ftm_mean_rolling_15_games',
      'fta_mean_rolling_5_games', 'fta_mean_rolling_15_games',
      'oreb_mean_rolling_5_games', 'oreb_mean_rolling_15_games',
      'dreb_mean_rolling_5_games', 'dreb_mean_rolling_15_games',
      'home_away_pts_mean_rolling_5_games', 'home_away_pts_mean_rolling_15_games',
      'home_away_reb_mean_rolling_5_games', 'home_away_reb_mean_rolling_15_games',
      'reboundchancesdefensive_mean_rolling_5_games', 'reboundchancesdefensive_mean_rolling_15_games',
      'reboundchancesoffensive_mean_rolling_5_games', 'reboundchancesoffensive_mean_rolling_15_games'
    ],
    'Pts+Ast+Reb': [
      'pts_mean_rolling_5_games', 'pts_mean_rolling_15_games',
      'ast_mean_rolling_5_games', 'ast_mean_rolling_15_games',
      'reb_mean_rolling_5_games', 'reb_mean_rolling_15_games',
      'min_mean_rolling_5_games', 'min_mean_rolling_15_games',
      'fgm_mean_rolling_5_games', 'fgm_mean_rolling_15_games',
      'fga_mean_rolling_5_games', 'fga_mean_rolling_15_games',
      'fg3m_mean_rolling_5_games', 'fg3m_mean_rolling_15_games',
      'fg3a_mean_rolling_5_games', 'fg3a_mean_rolling_15_games',
      'ftm_mean_rolling_5_games', 'ftm_mean_rolling_15_games',
      'fta_mean_rolling_5_games', 'fta_mean_rolling_15_games',
      'oreb_mean_rolling_5_games', 'oreb_mean_rolling_15_games',
      'dreb_mean_rolling_5_games', 'dreb_mean_rolling_15_games',
      'home_away_pts_mean_rolling_5_games', 'home_away_pts_mean_rolling_15_games',
      'home_away_ast_mean_rolling_5_games', 'home_away_ast_mean_rolling_15_games',
      'home_away_reb_mean_rolling_5_games', 'home_away_reb_mean_rolling_15_games',
      'secondaryassists_mean_rolling_5_games', 'secondaryassists_mean_rolling_15_games',
      'passes_mean_rolling_5_games', 'passes_mean_rolling_15_games',
      'touches_mean_rolling_5_games', 'touches_mean_rolling_15_games',
      'reboundchancesdefensive_mean_rolling_5_games', 'reboundchancesdefensive_mean_rolling_15_games',
      'reboundchancesoffensive_mean_rolling_5_games', 'reboundchancesoffensive_mean_rolling_15_games'
    ]
  };

  useEffect(() => {
    if (selectedPlayer && selectedFeature && token) {  // Ensure token is available
      const cacheKey = `${selectedPlayer}-${selectedFeature}-rolling`;
      
      if (!dataCache.current[cacheKey]) {
       
        
        const metrics = featureMetrics[selectedFeature] || [];
        const queryString = metrics.map(metric => `metrics=${metric}`).join('&');

        const endpoint = `players/filter_by_player/?player_id=${selectedPlayer}&${queryString}&x=1`;

        // Use fetchData function and pass the token
        fetchData(endpoint, token)
          .then(data => {
            if (data.length === 0) {
              console.error('No data received');
              return;
            }

            const newRollingData = {};
            metrics.forEach(metric => {
              newRollingData[metric] = data[0][metric];
            });

            // Calculate shooting percentages for summation features including PTS
            if (['Pts+Ast', 'Pts+Reb', 'Pts+Ast+Reb'].includes(selectedFeature)) {
              ['5', '15'].forEach(games => {
                newRollingData[`fg_percent_mean_rolling_${games}_games`] = (
                  (parseFloat(newRollingData[`fgm_mean_rolling_${games}_games`]) + parseFloat(newRollingData[`fg3m_mean_rolling_${games}_games`])) /
                  (parseFloat(newRollingData[`fga_mean_rolling_${games}_games`]) + parseFloat(newRollingData[`fg3a_mean_rolling_${games}_games`])) * 100
                ).toFixed(2);
                newRollingData[`fg3_percent_mean_rolling_${games}_games`] = (
                  parseFloat(newRollingData[`fg3m_mean_rolling_${games}_games`]) /
                  parseFloat(newRollingData[`fg3a_mean_rolling_${games}_games`]) * 100
                ).toFixed(2);
                newRollingData[`ft_percent_mean_rolling_${games}_games`] = (
                  parseFloat(newRollingData[`ftm_mean_rolling_${games}_games`]) /
                  parseFloat(newRollingData[`fta_mean_rolling_${games}_games`]) * 100
                ).toFixed(2);
              });
            }

            dataCache.current[cacheKey] = newRollingData;
            setRollingData(newRollingData);
          })
          .catch(error => {
            console.error('Error fetching rolling data:', error);
          });
      } else {
        setRollingData(dataCache.current[cacheKey]);
      }
    }
  }, [selectedPlayer, selectedFeature, token]);  // Include token as a dependency

  return rollingData;
};

export default useFetchL15TableData;
