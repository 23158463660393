import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import PlayerCard from '../main_dashboard_components/PlayerID';
import TeamVersus from '../main_dashboard_components/TeamVersus';
import InjuryReportTable from '../main_dashboard_components/InjuryReportTable';
import Last15Chart from '../main_dashboard_components/Last15Chart';
import Last15Table from '../main_dashboard_components/L15Table';
import ShootingChart from '../main_dashboard_components/RollingFeature';
import VersusTeamChart from '../main_dashboard_components/VersusTeamChart';
import { players } from '../components/PlayerInfo';
import './PlayerDashboard.css';
import PositionDefense from '../main_dashboard_components/PositionDefense';
import OpponentDefense from '../team_dashboard_components/OpponentDefense';
import PlayerLogsHistorical from '../main_dashboard_components/PlayerLogsHistorical';
import useAuth from '../hooks/useAuth';
import SimilarPlayerPerformance from '../main_dashboard_components/SimilarPlayerPerformance';
import PremiumFeature from '../main_dashboard_components/PremiumFeature';
import PlayerSelection from '../main_dashboard_components/PlayerSelection';
import PlayerPropsTable from '../main_dashboard_components/PlayerPropsTable';
import useFetchPlayerThresholds from '../components/PlayerThresholdData';

const PlayerDashboard = () => {
  const { teamColors, setTeamColors, selectedPlayer, setSelectedPlayer, thresholds } = usePlayerColors();
  const [searchParams, setSearchParams] = useSearchParams();
  // Always get the playerId from URL (if not present, use the first player)
  const paramPlayerId = searchParams.get('playerId') || Object.keys(players)[0];

  const [selectedFeature, setSelectedFeature] = useState('Total');
  const [showSum, setShowSum] = useState(true);
  const [isAuthenticated, , , username, roles] = useAuth();

  // 1) Responsive layout: Track screen width (debounced)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    let resizeTimeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => setScreenWidth(window.innerWidth), 100);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 2) Update selected player from URL
  useEffect(() => {
    if (paramPlayerId && paramPlayerId !== selectedPlayer) {
      setSelectedPlayer(paramPlayerId);
      if (players[paramPlayerId]) {
        setTeamColors(players[paramPlayerId].teamColors);
      }
    }
  }, [paramPlayerId, selectedPlayer, setSelectedPlayer, setTeamColors]);

  // 3) Fetch player thresholds based on the current player’s name.
  const playerName = players[selectedPlayer]?.name || 'Unknown Player';
  useFetchPlayerThresholds(playerName);

  // 4) Track page view (using Application Insights)
  useEffect(() => {
    (function () {
      const methods = [
        'trackEvent',
        'trackException',
        'trackMetric',
        'trackPageView',
        'trackTrace',
        'setAuthenticatedUserContext',
        'clearAuthenticatedUserContext'
      ];
      const appInsights = window.appInsights || function (config) {
        function trackFunc(func) {
          return function () {
            const args = arguments;
            appInsights.queue.push(() => {
              appInsights[func].apply(appInsights, args);
            });
          };
        }
        const ai = { config },
          d = document,
          scriptElem = d.createElement('script');
        function setup() {
          for (let i = 0; i < methods.length; i++) {
            ai[methods[i]] = trackFunc(methods[i]);
          }
        }
        scriptElem.src = config.url || 'https://az416426.vo.msecnd.net/scripts/a/ai.0.js';
        d.getElementsByTagName('head')[0].appendChild(scriptElem);
        setup();
        ai.queue = [];
        ai.version = '1.0';
        return ai;
      }({
        instrumentationKey: 'YOUR_INSTRUMENTATION_KEY'
      });
      window.appInsights = appInsights;
      appInsights.trackPageView({
        name: 'PlayerDashboard',
        properties: { component: 'PlayerDashboard' }
      });
    })();
  }, []);

  // 5) Info-button tooltip: (Consider refactoring to a dedicated component to avoid direct DOM queries)
  useEffect(() => {
    const buttons = document.querySelectorAll('.player-dashboard-info-button');
    const handlers = [];
    buttons.forEach((button) => {
      const handler = () => {
        const infoText = button.querySelector('.player-dashboard-info-text');
        if (infoText) {
          const rect = button.getBoundingClientRect();
          infoText.style.top = `${rect.top + window.scrollY - infoText.offsetHeight - 10}px`;
          infoText.style.left = `${rect.left + rect.width / 2 - infoText.offsetWidth / 2}px`;
        }
      };
      handlers.push({ button, handler });
      button.addEventListener('mouseenter', handler);
    });
    return () => {
      handlers.forEach(({ button, handler }) => {
        button.removeEventListener('mouseenter', handler);
      });
    };
  }, []);

  // 6) Feature toggles and mapping
  const features = [
    'Total', 'AST', 'REB',
    'Pts+Ast', 'Pts+Reb', 'Ast+Reb',
    'Pts+Ast+Reb', 'BLK', 'STL'
  ];
  const featureMap = {
    Points: 'Total',
    Assists: 'AST',
    Rebounds: 'REB',
    Steals: 'STL',
    Blocks: 'BLK',
    'Pts+Ast': 'Pts+Ast',
    'Pts+Reb': 'Pts+Reb',
    'Ast+Reb': 'Ast+Reb',
    'Pts+Ast+Reb': 'Pts+Ast+Reb'
  };
  const displayMap = {
    Total: 'PTS',
    AST: 'AST',
    REB: 'REB',
    'Pts+Ast': 'PTS+AST',
    'Pts+Reb': 'PTS+REB',
    'Ast+Reb': 'AST+REB',
    'Pts+Ast+Reb': 'PTS+AST+REB',
    BLK: 'BLK',
    STL: 'STL'
  };

  const handleFeatureClick = useCallback((feature) => {
    setSelectedFeature(feature);
  }, []);

  const handleToggle = useCallback(() => {
    setShowSum((prev) => !prev);
  }, []);

  const handlePlayerLogsFeatureSelect = useCallback((feature) => {
    const mappedFeature = featureMap[feature] || feature;
    setSelectedFeature(mappedFeature);
  }, [featureMap]);

  const getDisplayFeature = (feature) => displayMap[feature] || feature.toUpperCase();

  // When a new player is selected, update the URL.
  const handlePlayerSelect = useCallback((playerId) => {
    setSearchParams({ playerId });
    // The URL change triggers the useEffect above to update the context.
  }, [setSearchParams]);

  // 7) Choose layout based on screen width.
  const isMobileView = screenWidth < 900;

  // --- Desktop Layout ---
  const desktopLayout = (
    <>
      {/* ROW 1: PlayerCard + InjuryReport + TeamVersus */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-player-card">
          <PlayerCard selectedPlayer={selectedPlayer} handlePlayerSelect={handlePlayerSelect} />
        </div>
        <div className="player-dashboard-injury-report">
          <InjuryReportTable selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-team-versus">
          <TeamVersus showSum={showSum} />
        </div>
      </div>

      {/* ROW 2: Feature Menu */}
      <div className="player-dashboard-feature-menu">
        {features.map((feat) => (
          <div
            key={feat}
            className={`player-dashboard-feature-item ${selectedFeature === feat ? 'active' : ''}`}
            onClick={() => handleFeatureClick(feat)}
          >
            {getDisplayFeature(feat)}
            {thresholds[playerName]?.[feat] !== undefined &&
              thresholds[playerName][feat] !== null && (
                <div className="threshold-value">{thresholds[playerName][feat]}</div>
              )}
          </div>
        ))}
      </div>

      {/* ROW 3: Last15 Chart + Table */}
      <div className="player-dashboard-main-charts">
        <div className="player-dashboard-last-15">
          <Last15Chart showSum={showSum} onToggle={handleToggle} selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-last-15-table">
          <Last15Table selectedFeature={selectedFeature} />
        </div>
      </div>

      {/* ROW 4: VersusTeam + Shooting + PositionDefense + PremiumFeature */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-versus-team">
          <VersusTeamChart showSum={showSum} selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-shooting">
          <ShootingChart showSum={showSum} teamColors={teamColors} selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-position-defense">
          <PositionDefense selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-premium-feature">
          <PremiumFeature selectedFeature={selectedFeature} />
        </div>
      </div>

      {/* ROW 5: OpponentDefense + PropsTable */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-opponent-defense">
          <OpponentDefense teamColors={teamColors} selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-props-table">
          <PlayerPropsTable selectedFeature={selectedFeature} />
        </div>
      </div>

      {/* ROW 6: Historical Logs + Similar Perf + PlayerSelection */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-historical-logs">
          <PlayerLogsHistorical
            selectedFeature={selectedFeature}
            setSelectedFeature={handlePlayerLogsFeatureSelect}
          />
        </div>
        <div className="player-dashboard-similar-performance">
          <SimilarPlayerPerformance selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-player-selection">
          <PlayerSelection selectedFeature={selectedFeature} />
        </div>
      </div>
    </>
  );

  // --- Mobile (Merged) Layout ---
  const mobileMergedLayout = (
    <>
      {/* ROW 1: PlayerCard + InjuryReport + TeamVersus */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-player-card">
          <PlayerCard selectedPlayer={selectedPlayer} handlePlayerSelect={handlePlayerSelect} />
        </div>
        <div className="player-dashboard-injury-report">
          <InjuryReportTable selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-team-versus">
          <TeamVersus showSum={showSum} />
        </div>
      </div>

      {/* ROW 2: Feature Menu */}
      <div className="player-dashboard-feature-menu">
        {features.map((feat) => (
          <div
            key={feat}
            className={`player-dashboard-feature-item ${selectedFeature === feat ? 'active' : ''}`}
            onClick={() => handleFeatureClick(feat)}
          >
            {getDisplayFeature(feat)}
            {thresholds[playerName]?.[feat] !== undefined &&
              thresholds[playerName][feat] !== null && (
                <div className="threshold-value">{thresholds[playerName][feat]}</div>
              )}
          </div>
        ))}
      </div>

      {/* ROW 3: Last15 Chart + Table */}
      <div className="player-dashboard-main-charts">
        <div className="player-dashboard-last-15">
          <Last15Chart showSum={showSum} onToggle={handleToggle} selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-last-15-table">
          <Last15Table selectedFeature={selectedFeature} />
        </div>
      </div>

      {/* Merged ROW 4 & 5: Combined layout */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-versus-team">
          <VersusTeamChart showSum={showSum} selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-shooting">
          <ShootingChart showSum={showSum} teamColors={teamColors} selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-position-defense">
          <PositionDefense selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-opponent-defense">
          <OpponentDefense teamColors={teamColors} selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-premium-feature">
          <PremiumFeature selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-props-table">
          <PlayerPropsTable selectedFeature={selectedFeature} />
        </div>
      </div>

      {/* ROW 6: Historical Logs + Similar Perf + PlayerSelection */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-historical-logs">
          <PlayerLogsHistorical
            selectedFeature={selectedFeature}
            setSelectedFeature={handlePlayerLogsFeatureSelect}
          />
        </div>
        <div className="player-dashboard-similar-performance">
          <SimilarPlayerPerformance selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-player-selection">
          <PlayerSelection selectedFeature={selectedFeature} />
        </div>
      </div>
    </>
  );

  return (
    <div className="player-dashboard-container">
      {isMobileView ? mobileMergedLayout : desktopLayout}
    </div>
  );
};

export default PlayerDashboard;
