import { teamsDict } from './TeamInfo';
import schedule from '../matchup_dashboard_components/data/nba_schedule.json';

// Helper function to generate random picks and top picks
const generateMockPicks = () => {
  return {
    picks: Math.floor(Math.random() * 60) + 30, // Random picks between 30 and 90
    topPicks: Math.floor(Math.random() * 15) + 5  // Random top picks between 5 and 20
  };
};

// Helper function to generate a matchup object
const generateMatchup = (homeTeam, awayTeam, date, time, picks, topPicks) => {
  return {
    matchup: `${awayTeam} @ ${homeTeam}`, // Display as "Away @ Home"
    date,
    time,
    picks,
    topPicks
  };
};

/**
 * Return "today" in EST as a YYYY-MM-DD string.
 * e.g., "2025-01-11"
 */
const getTodayInEST = () => {
  // Convert current system time to an EST string
  const nowESTString = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
  const nowEST = new Date(nowESTString);
  return nowEST.toISOString().split('T')[0];
};

/**
 * Helper to convert a tip-off time (e.g., "7:30 PM ET") into minutes since midnight.
 */
const parseTime = (timeStr) => {
  // Remove "ET" if present and trim extra spaces
  timeStr = timeStr.replace('ET', '').trim();
  const [time, period] = timeStr.split(' ');
  let [hours, minutes] = time.split(':').map(Number);
  if (period === 'PM' && hours !== 12) hours += 12;
  if (period === 'AM' && hours === 12) hours = 0;
  return hours * 60 + minutes;
};

/**
 * Extract upcoming matchups (from today forward). For each game in the sorted schedule,
 * if neither team has already been assigned a matchup then that game becomes the next matchup for both.
 */
const extractUpcomingMatchups = (schedule) => {
  const seenTeams = new Set();
  const matchups = [];
  const todayEST = getTodayInEST();

  // Filter out games that are before today or have a "TBD" time,
  // and sort the remaining games by Date and tip-off time.
  const upcomingGames = schedule
    .filter(game => game['Tip-off Time'] !== 'TBD' && game.Date >= todayEST)
    .sort((a, b) => {
      if (a.Date === b.Date) {
        return parseTime(a['Tip-off Time']) - parseTime(b['Tip-off Time']);
      }
      return a.Date.localeCompare(b.Date);
    });

  // Iterate over the sorted upcoming games.
  upcomingGames.forEach(game => {
    const { 'Home Team': homeTeam, 'Away Team': awayTeam, Date: date, 'Tip-off Time': time } = game;
    
    // Only use this game if neither team has been assigned a matchup yet.
    if (!seenTeams.has(homeTeam) && !seenTeams.has(awayTeam)) {
      seenTeams.add(homeTeam);
      seenTeams.add(awayTeam);
      
      const { picks, topPicks } = generateMockPicks();
      matchups.push(generateMatchup(homeTeam, awayTeam, date, time, picks, topPicks));
    }
  });

  return matchups;
};

export const matchups = extractUpcomingMatchups(schedule);
