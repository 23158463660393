import React, { useState, useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { fetchData } from '../api';
import './ParlayBuilder.css';

const propMapping = {
  "Points": "PTS",
  "Rebounds": "REB",
  "Assists": "AST",
  "Pts+Ast": "PTS+AST",
  "Reb+Ast": "AST+REB",
  "Pts+Reb": "PTS+REB",
  "Pts+Ast+Reb": "PTS+AST+REB",
};

// Example historical accuracy for >70%
const over70Accuracies = {
  "PTS": 0.56,
  "AST": 0.61,
  "REB": 0.62,
  "PTS+REB": 0.54,
  "PTS+AST+REB": 0.56,
  "PTS+AST": 0.55,
  "AST+REB": 0.55,
};

// Example historical accuracy for >60%
const over60Accuracies = {
  "PTS": 0.55,
  "AST": 0.59,
  "REB": 0.59,
  "PTS+REB": 0.53,
  "PTS+AST+REB": 0.55,
  "PTS+AST": 0.54,
  "AST+REB": 0.54,
};

const DEFAULT_BANKROLL = 1000;

/**
 * Converts American odds to decimal odds.
 */
function toDecimalOdds(rawOdds) {
  const odds = parseFloat(rawOdds);
  if (Number.isNaN(odds)) return 0;
  if (Math.abs(odds) <= 3) return odds;
  return odds > 0 ? 1 + odds / 100 : 1 + 100 / Math.abs(odds);
}

/**
 * Adjusts raw probability p using custom rules.
 */
function adjustProbability(p, prop) {
  if (p < 0.55) {
    return 0.0;
  } else if (p < 0.60) {
    const base = over60Accuracies[prop];
    return base ? Math.max(base - 0.02, 0) : Math.max(p - 0.02, 0);
  } else if (p < 0.70) {
    const base = over60Accuracies[prop];
    return base || p;
  } else {
    const base = over70Accuracies[prop];
    return base || p;
  }
}

/**
 * Calculates Kelly fraction: f = (p*(b+1) - 1)/b.
 */
function kellyFraction(prob, oddsDecimal) {
  const b = oddsDecimal - 1;
  if (b <= 0) return 0;
  const f = (prob * (b + 1) - 1) / b;
  return f > 0 ? f : 0;
}

/**
 * Computes bet allocations based on bet type and bankroll.
 * For singles: returns an array of individual amounts (based on Kelly fractions multiplied by bankroll).
 * For parlay: returns an array where each leg gets the same overall computed amount.
 */
function computeBetAllocations(bets, betType, bankroll) {
  if (!bets.length) return [];
  
  if (betType === 'single') {
    let fractions = bets.map((bet) => {
      let rawProb = parseFloat(bet.feature_probability);
      if (Number.isNaN(rawProb)) rawProb = 0.55;
      const prop = bet.prop || propMapping[bet.featureDisplay] || "unknown_prop";
      const adjustedProb = adjustProbability(rawProb, prop);
      const decimalOdds = toDecimalOdds(bet.odds);
      return kellyFraction(adjustedProb, decimalOdds);
    });
    const totalF = fractions.reduce((sum, f) => sum + f, 0);
    if (totalF > 1) {
      fractions = fractions.map(f => f / totalF);
    }
    // Return allocation for each leg.
    return fractions.map(f => parseFloat((f * bankroll).toFixed(2)));
  }
  
  if (betType === 'parlay') {
    let parlayProb = 1;
    let parlayOdds = 1;
    bets.forEach((bet) => {
      let rawProb = parseFloat(bet.feature_probability);
      if (Number.isNaN(rawProb)) rawProb = 0.55;
      const prop = bet.prop || propMapping[bet.featureDisplay] || "unknown_prop";
      const adjustedProb = adjustProbability(rawProb, prop);
      const decimalOdds = toDecimalOdds(bet.odds);
      parlayProb *= adjustedProb;
      parlayOdds *= decimalOdds;
    });
    const fParlay = kellyFraction(parlayProb, parlayOdds);
    const totalBetSize = parseFloat((fParlay * bankroll).toFixed(2));
    return bets.map(() => totalBetSize);
  }
  return [];
}

const ParlayBuilder = ({
  parlayPlayers,
  removeFromParlay,
  clearParlay,
  keycloakId,
  token
}) => {
  const [betType, setBetType] = useState('parlay'); // "parlay" or "single"
  const [computedAllocations, setComputedAllocations] = useState([]);
  // Bankroll (overall available amount) is separate and editable.
  const [bankroll, setBankroll] = useState(DEFAULT_BANKROLL);
  // Overall bet amount for the current ticket; initially computed from bankroll & Kelly.
  const [overallBet, setOverallBet] = useState(0);

  // Force "single" mode if only one player.
  useEffect(() => {
    if (parlayPlayers.length === 1) {
      setBetType('single');
    }
  }, [parlayPlayers]);

  // Recompute Kelly allocations whenever players, bet type, or bankroll change.
  useEffect(() => {
    const allocations = computeBetAllocations(parlayPlayers, betType, bankroll);
    setComputedAllocations(allocations);
    if (betType === 'single') {
      // For singles, the default overall bet is the sum of the computed allocations.
      const total = allocations.reduce((sum, amt) => sum + amt, 0);
      setOverallBet(total);
    } else {
      // For parlay, default overall bet is the computed amount (all legs are the same).
      setOverallBet(allocations[0] || bankroll);
    }
  }, [parlayPlayers, betType, bankroll]);

  // For parlay mode, compute combined values for summary.
  const parlaySummary = () => {
    let combinedRawProb = 1, combinedHistProb = 1, combinedOdds = 1;
    parlayPlayers.forEach((player) => {
      let rawProb = parseFloat(player.feature_probability);
      if (Number.isNaN(rawProb)) rawProb = 0.55;
      combinedRawProb *= rawProb;
      const prop = player.prop || propMapping[player.featureDisplay] || player.featureDisplay || "unknown_prop";
      const adjustedProb = adjustProbability(rawProb, prop);
      combinedHistProb *= adjustedProb;
      const decimalOdds = toDecimalOdds(player.odds);
      combinedOdds *= decimalOdds;
    });
    return {
      bankroll, // display the current bankroll
      overallBet,
      combinedRawProb,
      combinedHistProb,
      combinedOdds,
    };
  };

  // For singles, redistribute the overallBet among legs proportionally.
  const redistributeSingles = () => {
    const totalComputed = computedAllocations.reduce((sum, amt) => sum + amt, 0);
    if (totalComputed === 0) return computedAllocations.map(() => 0);
    return computedAllocations.map(amt => parseFloat(((amt / totalComputed) * overallBet).toFixed(2)));
  };

  const confirmBet = async () => {
    if (!parlayPlayers.length) {
      alert("Add players to your bet before confirming.");
      return;
    }
    for (const player of parlayPlayers) {
      if (!player.game_date || !player.next_opponent || !player.over_under) {
        alert(
          `Missing required fields for player ${player.player_name || "unknown"}: ` +
          `${!player.game_date ? "game_date " : ""}` +
          `${!player.next_opponent ? "opponent " : ""}` +
          `${!player.over_under ? "over_under" : ""}`
        );
        return;
      }
    }
    let finalAllocations;
    if (betType === 'single') {
      finalAllocations = redistributeSingles();
    } else {
      finalAllocations = computedAllocations.map(() => overallBet);
    }

    // Prepare payload; each bet will include:
    // - bet_type ("parlay" or "single")
    // - amount: for singles, distributed amounts; for parlay, same overallBet for each leg.
    const bets = parlayPlayers.map((player, index) => {
      const propToSave = player.prop || propMapping[player.featureDisplay] || player.featureDisplay || "unknown_prop";
      return {
        player_bet_on: player.player_name,
        opponent: player.next_opponent,
        game_date: player.game_date,
        odds: parseFloat(player.odds),
        sportsbook: player.sportsbook || "",
        probability: parseFloat(player.feature_probability),
        team: player.team || "",
        threshold: parseFloat(player.threshold),
        over_under: player.over_under,
        prediction: player.feature_prediction || 23.5,
        prop: propToSave,
        bet_type: betType,
        timestamp: new Date().toISOString(),
        amount: finalAllocations[index] || 0,
      };
    });
    console.log('bets', bets);
    const payload = {
      auth0_id: keycloakId,
      bets: bets,
    };

    try {
      await fetchData('user-picks/create_ticket_and_bets/', token, 'POST', payload);
      alert(`Your ${betType === 'parlay' ? 'Parlay' : 'Single'} Bet has been saved.`);
      clearParlay();
    } catch (error) {
      console.error("Error saving bet:", error);
      alert("An error occurred while saving your bet. Please try again.");
    }
  };

  const handleRemovePlayer = (playerToRemove) => {
    removeFromParlay(playerToRemove.name);
  };

  // For singles, get redistributed amounts.
  const redistributedAmounts = betType === 'single' ? redistributeSingles() : [];
  const summary = betType === 'parlay' ? parlaySummary() : null;

  return (
    <div className="parlay-builder-container">
      <div className="parlay-builder-header">
        <div className="header-left">
          <h3>Ticket Builder</h3>
          <div className="bankroll-display">
            <strong>Bankroll:</strong>
            <input
              type="number"
              className="bankroll-input"
              value={bankroll}
              onChange={(e) =>
                setBankroll(e.target.value === '' ? 0 : parseFloat(e.target.value))
              }
            />
          </div>
        </div>
        <div className="parlay-builder-top-buttons">
          {parlayPlayers.length > 1 ? (
            <select
              value={betType}
              onChange={(e) => setBetType(e.target.value)}
              className="bet-type-select"
            >
              <option value="parlay">Parlay</option>
              <option value="single">Single</option>
            </select>
          ) : (
            <div className="bet-type-forced">Single Bet</div>
          )}
          <button className="parlay-builder-refresh-btn" onClick={clearParlay}>
            🔄
          </button>
        </div>
      </div>

      <span className="parlay-builder-legs">
        {parlayPlayers.length} {parlayPlayers.length === 1 ? "Leg" : "Legs"}
      </span>

      {!parlayPlayers.length ? (
        <div className="parlay-builder-empty-message">Add players to the ticket</div>
      ) : (
        <table className="parlay-builder-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Prop</th>
              <th>Line</th>
              <th>Pick</th>
              <th>Odds</th>
              <th>Conf</th>
              <th>Hist Acc.</th>
              {betType === 'single' && <th>Amount</th>}
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {parlayPlayers.map((player, index) => {
              const shortProp = player.prop || propMapping[player.featureDisplay] || player.featureDisplay || "unknown_prop";
              let rawProb = parseFloat(player.feature_probability);
              if (Number.isNaN(rawProb)) rawProb = 0.55;
              const histProb = adjustProbability(rawProb, shortProp);
              return (
                <tr key={index}>
                  <td>{player.player_name}</td>
                  <td>{shortProp}</td>
                  <td>{player.threshold}</td>
                  <td>{player.over_under}</td>
                  <td>{player.odds}</td>
                  <td>{(rawProb * 100).toFixed(0)}%</td>
                  <td>{(histProb * 100).toFixed(0)}%</td>
                  {betType === 'single' && (
                    <td>
                      ${redistributedAmounts[index] ? redistributedAmounts[index].toFixed(2) : '0.00'}
                    </td>
                  )}
                  <td>
                    <button onClick={() => handleRemovePlayer(player)} className="parlay-builder-remove-btn">
                      <FaTrashAlt />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      <div className="parlay-builder-bottom">
        <div className="parlay-builder-summary">
          <div>
            <strong>Overall Bet:</strong>
            <input
              type="number"
              className="parlay-builder-overall-bet-input"
              value={overallBet}
              onChange={(e) =>
                setOverallBet(e.target.value === '' ? 0 : parseFloat(e.target.value))
              }
            />
          </div>
          {betType === 'parlay' && summary && (
            <>
              <div>
                <strong>Confidence:</strong> {(summary.combinedRawProb * 100).toFixed(2)}%
              </div>
              <div>
                <strong>Historical Acc.:</strong> {(summary.combinedHistProb * 100).toFixed(2)}%
              </div>
              <div>
                <strong>Total Odds:</strong> {summary.combinedOdds.toFixed(2)}
              </div>
              <div>
                <strong>Possible Amount:</strong> {(overallBet * summary.combinedOdds).toFixed(1)}
              </div>
            </>
          )}
          {betType === 'single' && (
            <div>
              <em>The overall bet will be redistributed across singles proportionally using the Kelly Criterion.</em>
            </div>
          )}
        </div>
        <button className="parlay-builder-confirm-btn" onClick={confirmBet}>
          Confirm {betType === 'parlay' ? "Parlay" : "Singles"} Ticket
        </button>
      </div>
    </div>
  );
};

export default ParlayBuilder;
