import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import "./LandingPage.css";

// Import your local assets as needed
import lbj from "./assets/ad_dash.png";
import mlScreenshot from "./assets/top_pics.png";
import hero from "./assets/hero.svg";
import tactics from "./assets/tactics.png";
import court from "./assets/court.png";
import dunks from "./assets/dunks.png";
import injuryReport from "./assets/injury_report.png";
import purple_circles from "./assets/purple_circles.svg";
import white_circles from "./assets/white_circles.svg";
import top_picks from "./assets/top_picks.svg";
import backgroundCurve from "./assets/background_curve.svg";
import backgroundCurveLeft from "./assets/backgroundCurveLeft.svg";

const LandingPage = () => {
  // Auth and navigation
  const [isAuthenticated, getToken, username, roles, loading, login, logout] = useAuth();
  const navigate = useNavigate();

  // Simple FAQ toggle
  const [openFAQ, setOpenFAQ] = useState(null);
  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const faqs = [
    {
      question: "How can I use Showstone?",
      answer:
        "Showstone provides comprehensive analytics and data visualization tools for NBA statistics and player performance predictions.",
    },
    {
      question: "What features are included with the free version?",
      answer:
        "The free version offers access to basic NBA team and player statistics as well as matchup statistics and other features.",
    },
    {
      question: "How can I get in touch with you",
      answer: "You can email us at showstone@showstone.io !",
    },
  ];

  // Key Features data & slides (carousel)
  const features = [
    {
      title: "ADVANCED PLAYER AND TEAM STATISTICS",
      text: "Advanced player and team statistics reveal in-depth performance metrics, from shooting efficiency to defensive impact and player influence on team dynamics.",
      image: tactics,
    },
    {
      title: "MATCHUP STATS AND ANALYTICS",
      text: "Matchup analytics deliver in-depth comparisons of player and team dynamics, revealing strengths, weaknesses, and key factors for each game.",
      image: court,
    },
    {
      title: "PLAYER PROP PREDICTIONS",
      text: "Data-driven predictions to highlight key performance trends for individual players, from scoring and assists to rebounds and more.",
      image: dunks,
    },
    {
      title: "ADVANCED INJURY REPORT",
      text: "Track injury reports and evaluate how player absences or returns can affect team performance. Past games without player analyzed instantly.",
      image: injuryReport,
    },
  ];

  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        // Mobile: 1 feature per slide
        setSlides(features.map((f) => [f]));
      } else {
        // Desktop: 2 features per slide
        const newSlides = [];
        for (let i = 0; i < features.length; i += 2) {
          newSlides.push(features.slice(i, i + 2));
        }
        setSlides(newSlides);
      }
      setCurrentIndex(0);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };
  const handleNext = () => {
    setCurrentIndex((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  // This helper will determine what happens when the free trial button is clicked.
  // If the user is already authenticated, we navigate them to the free trial page.
  // Otherwise, we call login() to prompt sign-up/login.
  const handleFreeTrialClick = () => {
    if (isAuthenticated) {
      // Navigate to your free trial page; adjust the route as needed.
      navigate("/Subscription");
    } else {
      login();
    }
  };

  return (
    <div className="landing-page-container">
      {/* HERO SECTION */}
      <section className="hero-section">
        <div className="hero-bg-wrapper">
          <img src={hero} alt="Hero SVG" className="hero-bg" />
        </div>
        <div className="hero-content">
          <h1 className="hero-title">Tomorrow’s Results, Today.</h1>
          <p className="hero-subtitle">
            Your <span className="highlight">NBA Stats &amp; Future Projections</span>{" "}
            <br />Journey Starts Here.
          </p>
          <button className="hero-button" onClick={handleFreeTrialClick}>
            {isAuthenticated ? "Free Trial" : "Sign Up for Free"}
          </button>
        </div>
      </section>

      {/* WHAT WE OFFER SECTION (example) */}
      <section className="what-we-offer-section">
        <div className="offer-header">
          <h2>What We Offer</h2>
          <div className="circle-decor">
            <img src={purple_circles} alt="Purple decorative circle" />
          </div>
        </div>
        <div className="offer-grid">
          <div className="offer-item">
            <h3>Player Performance Predictions</h3>
            <p>
              Unlock the most accurate NBA player performance predictions through our
              sophisticated machine learning model. We utilize a wealth of historical
              data, player trends, and advanced modeling to provide you with precise future
              projections.
            </p>
          </div>
          <div className="offer-item">
            <h3>Comprehensive NBA stats</h3>
            <p>
              Dive into advanced real-time player performance and team statistics. From
              scoring trends to defensive matchups, unlock the data that shapes the game.
            </p>
          </div>
          <div className="offer-item">
            <h3>Interactive Data Visualization</h3>
            <p>
              Through clear and stylish visualizations on our <b>Analytics Hub</b>, we provide
              instant access to all the game statistics you’ll ever need. Discover key matchup
              metrics through dynamic visuals and intuitive tools.
            </p>
          </div>
        </div>
      </section>

      {/* MACHINE LEARNING SECTION */}
      <section className="machine-learning-section">
        <img
          src={backgroundCurve}
          alt="Purple curve background"
          className="ml-background-svg"
        />
        <div className="ml-container">
          <h2 className="ml-title">Our Tech</h2>
          <div className="circle-decor">
            <img src={white_circles} alt="White decorative circle" />
          </div>
          <div className="ml-content">
            <div className="ml-image">
              <img src={top_picks} alt="ML picks screenshot" className="ml-screenshot" />
            </div>
            <div className="ml-text">
              <h>Machine Learning Model</h>
              <p>
                Our machine learning model provides estimations across 7 different player props,
                achieving an overall accuracy of <b>58.4%</b>. For high-confidence predictions (Top
                Picks), the model delivers an impressive accuracy of <b>64.6%</b>.
              </p>
              <p>
                We’ve developed a cutting-edge confidence estimation system that evaluates the
                reliability of each prediction. This advanced feature empowers users to filter
                and focus on the most promising options with clarity and precision.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* PRICING SECTION */}
      <section className="pricing-section">
        <h2 className="pricing-title">Subscription Options</h2>
        <div className="circle-decor">
          <img src={purple_circles} alt="White decorative circle" />
        </div>
        <div className="pricing-cards-wrapper">
          {/* === FREE CARD === */}
          <div className="pricing-card free-card">
            {/* Purple label on top */}
            <div className="ribbon-label">No card required!</div>

            <h3 className="card-tier">Showstone Free</h3>
            <div className="card-price">$0</div>

            <ul className="card-features">
              <li>Create Account for Free</li>
              <li>Access our stats and NBA content</li>
            </ul>

            <button className="card-button" onClick={handleFreeTrialClick}>
              {isAuthenticated ? "Sign Up For Free" : "Sign Up for Free"}
            </button>
          </div>

          {/* === PREMIUM CARD === */}
          <div className="pricing-card premium-card">
            <h3 className="card-tier">Showstone Premium</h3>
            <div className="card-price">$14.99</div>
            <div className="billed-monthly">(billed monthly)</div>

            <ul className="card-features">
              <li>Model Projections</li>
              <li>Advanced Features</li>
              <li>Automatic Bankroll Management</li>
              <li>Priority Customer Support</li>
            </ul>

            <button className="card-button" onClick={() => navigate("/Subscription")}>
              Free Trial
            </button>
          </div>
        </div>
      </section>

      {/* FAQ SECTION */}
      <section className="faq-section">
        {/* Background on the left */}
        <img
          src={backgroundCurveLeft}
          alt="Background Curve Left"
          className="faq-bg-left"
        />

        <div className="faq-container">
          {/* Title */}
          <h2 className="faq-title">FAQ</h2>

          {/* Decorative circles under the title */}
          <div className="circle-decor">
            <img src={white_circles} alt="White decorative circle" />
          </div>

          {/* FAQ content */}
          <div className="faq-content">
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <div className="faq-question" onClick={() => toggleFAQ(index)}>
                  {faq.question}
                  <span className="faq-icon">{openFAQ === index ? "-" : "+"}</span>
                </div>
                <div
                  className="faq-answer"
                  style={{ display: openFAQ === index ? "block" : "none" }}
                >
                  {faq.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
